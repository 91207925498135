import React, { useState } from "react";
import "./styles.scss";
import Header from "components/Headers/Header";
import InputText from "components/Inputs/InputText";
import { useNavigate } from "react-router-dom";
import SubText from "components/Texts/SubText";
import openedEye from "../../assets/icons/eyes/opened-eye-gray.svg";
import Separator from "components/Separator";
import PasswordStrengthBar from "react-password-strength-bar";
import Button from "components/Buttons/Button";
import createUser from "api/auth/postCreateUser";
import { IToast } from "types/toast";
import { useUserInfo } from "contexts/userInfo";
import { useLoginSignUpNavigation } from "contexts/useLoginSignUpNavigation";
import patchUserLocation from "api/user/patchUserLocation";
import Toast from "components/Toast";
import { handleGetLocation } from "utils/getUserGeoLocation";
import CheckCircleGreen from "../../assets/icons/checks/check-circle-green.svg";
import patchUserCoordinates from "api/user/patchUserCoordinates";

const CreateAccountPage: React.FC = () => {
  const [displayName, setDisplayName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [newsLetter, setNewsLetter] = useState(true);
  const [createdPassword, setCreatedPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordInputWarning, setPasswordInputWarning] = useState(false);
  const [displayNameWarning, setDisplayNameWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const { setUserInfo } = useUserInfo();
  const { setComponent } = useLoginSignUpNavigation();
  const navigate = useNavigate();
  const [emailInputWarning, setEmailInputWarning] = useState(false);

  const closePageVerifyEmail = () => {
    setDisplayName("");
    setUserEmail("");
    setCreatedPassword("");
    setConfirmPassword("");

    setComponent("/login");
  };

  const sedEmailVerify = (
    <section className="email-verify-content-modal">
      <img src={CheckCircleGreen} alt="check" className="status-request" />

      <h5 className="no-margin email-verify-content-modal__title">
        Verification email sent successfully.
      </h5>
      <p className="small-p email-verify-content-modal__explanation-text">
        Check your email inbox and click on the link we sent you. Also check
        your spam folder
      </p>
    </section>
  );

  const createAccountSubmit = async () => {
    if (displayName.trim().length < 3) {
      setToastConfig({
        description: "Your display name should be at least 3 characters long",
        title: "Short Display name",
        type: "warning",
      });

      setToastShow(true);
      setDisplayNameWarning(true);

      setTimeout(() => {
        setToastShow(false);
        setDisplayNameWarning(false);
      }, 4000);
      return;
    }

    if (userEmail.length < 5) {
      setToastShow(true);
      setEmailInputWarning(true);

      setToastConfig({
        description: "You need to add your email to continue",
        title: "Email is empty",
        type: "info",
      });

      setTimeout(() => {
        setEmailInputWarning(false);
        setToastShow(false);
      }, 4000);

      return;
    }

    if (!userEmail.includes("@") || !userEmail.includes(".com")) {
      setToastShow(true);
      setEmailInputWarning(true);

      setToastConfig({
        description: "Add a valid email to continue.",
        title: "Invalid Email",
        type: "info",
      });

      setTimeout(() => {
        setEmailInputWarning(false);
        setToastShow(false);
      }, 4000);

      return;
    }

    if (confirmPassword.trim().length < 8) {
      setToastConfig({
        description: "Your password should be at least 8 characters long",
        title: "Short Pass",
        type: "warning",
      });

      setToastShow(true);
      setPasswordInputWarning(true);

      setTimeout(() => {
        setToastShow(false);
        setPasswordInputWarning(false);
      }, 4000);
      return;
    }

    if (createdPassword.trim() !== confirmPassword.trim()) {
      setToastConfig({
        description: "Check passwords and try again",
        title: "Passwords don't match",
        type: "warning",
      });

      setToastShow(true);
      setPasswordInputWarning(true);

      setTimeout(() => {
        setToastShow(false);
        setPasswordInputWarning(false);
      }, 4000);
      return;
    }

    setIsLoading(true);

    const prepareData = {
      displayName: displayName,
      email: userEmail,
      password: createdPassword,
    };
    const request = await createUser(prepareData);

    switch (request.status) {
      case 201:
        handleGetLocation()
          .then(async (res) => {
            setUserInfo("@air-fly_user_lat", JSON.stringify(res.latitude));
            setUserInfo("@air-fly_user_lon", JSON.stringify(res.longitude));

            await patchUserCoordinates(
              request.res.access_token,
              res.longitude,
              res.latitude
            );
          })
          .catch((res) =>
            setUserInfo("@air-fly_geo_location_permission", "false")
          )
          .finally(() => {
            setUserInfo("@air-fly_access-token", request.res.access_token);
            setUserInfo("@air-fly_display_name", request.res.displayName);
            setUserInfo("@air-fly_user_avatar", request.res?.avatarUrl);
            setUserInfo("@air-fly_user_email", request.res.email);
            setUserInfo("@air-fly_user_id", request.res.id);
            setUserInfo("@air-fly_newsletter", JSON.stringify(newsLetter));
            setUserInfo("@air-fly_user_role", request.res.role);
            setUserInfo("@air-fly_user_country", request.res?.country);
            setUserInfo("@air-fly_user_city", request.res?.city);

            setComponent(sedEmailVerify);

            setTimeout(() => closePageVerifyEmail(), 6000);
          });

        break;
      case 409:
        setToastConfig({
          description: "Already registered user",
          title: "Oops...!",
          type: "info",
        });
        setToastShow(true);

        setTimeout(() => setToastShow(false), 4000);

        break;
      default:
        setToastConfig({
          description:
            "Something went wrong on user creation. Please try again.",
          title: "Error",
          type: "error",
        });
        setToastShow(true);

        setTimeout(() => setToastShow(false), 4000);
        break;
    }
  };

  return (
    <div className="create-account-page padding-hor-24 padding-bottom-24">
      <Header
        headerIcon="airFly"
        backButtonClick={() => setComponent("/login")}
        title={
          <>
            Create your <br />
            account
          </>
        }
        titleClass="header"
      />

      <InputText
        inputType="text"
        placeholder="Display Name"
        value={displayName}
        onChangeInput={setDisplayName}
        isWarning={displayNameWarning}
      />
      <SubText icon={openedEye}>This will be shown on your profile</SubText>
      <Separator className="margin-top-8 margin-bottom-24" />
      <InputText
        inputType="email"
        placeholder="Email"
        onChangeInput={setUserEmail}
        value={userEmail}
        isWarning={emailInputWarning}
      />
      <SubText
        checkAnswer
        checkValueCallback={setNewsLetter}
        className="margin-vert-8"
      >
        I want to receive news, updates, and offers from AirFly.
      </SubText>
      <div className="password-inputs-container">
        <InputText
          value={createdPassword}
          placeholder="Password"
          inputType="password"
          onChangeInput={(e) => setCreatedPassword(e)}
          isWarning={passwordInputWarning}
          className="no-margin"
        />
        <InputText
          value={confirmPassword}
          placeholder="Confirm Password"
          inputType="password"
          onChangeInput={(e) => setConfirmPassword(e)}
          isWarning={passwordInputWarning}
          className="no-margin"
        />
        <PasswordStrengthBar
          minLength={8}
          barColors={[
            "#dddddd",
            "var(--peach)",
            "#FFC045",
            "var(--green)",
            "var(--green",
          ]}
          scoreWords={[
            "(add more characters to strengthen) very weak",
            "(add more characters to strengthen) weak",
            "good",
            "strong",
            "very strong",
          ]}
          scoreWordClassName="text-password-bar"
          shortScoreWord="Your password should be at least 8 characters long"
          password={createdPassword}
        />
      </div>
      <div className="create-account-page__buttons-container padding-vert-16">
        <Button
          disabled={isLoading}
          buttonStyle="quintenary"
          onClick={() => setComponent("/login")}
        >
          Already have an account?<span className="highlight">Login</span>
        </Button>
        <Button
          disabled={isLoading}
          buttonStyle="primary"
          onClick={createAccountSubmit}
        >
          Create Account
        </Button>
      </div>

      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default CreateAccountPage;
