import React, { useEffect, useState, useCallback, useRef } from "react";
import "./styles.scss";
import { FlightAvailability } from "types/flights";
import { useUserInfo } from "contexts/userInfo";
import { useDeskNavigation } from "contexts/useDeskNavigation";
import AirlinesSimpleTickets from "components/Cards/ArlinesSimpleTicket";
// import { FavoriteFlies } from "../../mocks/favoriteFlies";
import getListFavoriteFlights from "api/favoriteFlights/getListFavoriteFlights";
import { ClassesFlightsType } from "types/flightDetails";
import TextActionCard from "components/Cards/TextActionCard";

import heartIconOrange from "../../assets/icons/heart/heart-icon-fill-orange.svg";
import { IAlerts } from "types/alerts";
import getUserAlerts from "api/alerts/getUserAlerts";

const FavoriteFliesRoutesPage: React.FC = () => {
  const [selectedFlightId, setSelectedFlightId] = useState("");
  const [flightsList, setFlights] = useState<FlightAvailability[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setSecondaryScreen, setPrincipalScreen } = useDeskNavigation();
  const [alertList, setAlertList] = useState<string[]>([]);
  const { userInfo } = useUserInfo();

  useEffect(() => {
    likedFlights();
    userAlertList();
  }, []);

  const likedFlights = async () => {
    setIsLoading(true);
    try {
      const request = await getListFavoriteFlights(
        userInfo["@air-fly_access-token"],
        { skip: 0, take: 50 }
      );
      const requestData: FlightAvailability[] = request.res.data;

      setFlights(requestData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const selectFlightHandler = (id: string, flight: FlightAvailability) => {
    setSelectedFlightId(id);
    setSecondaryScreen(`/flight-details`, { flightId: id });
  };

  const exploreFlights = () => {
    setPrincipalScreen("/add-phone-number");
  };

  const userAlertList = async () => {
    try {
      const request = await getUserAlerts(userInfo["@air-fly_access-token"]);
      const alerts: IAlerts[] = request.res;

      setAlertList(
        alerts
          .map((alert) => alert.notifications.map((alert) => alert.flightId))
          .flat()
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="favorite-flies-routes">
      <main className="favorite-flies-routes__main padding-16">
        {!flightsList.length && !isLoading && (
          <TextActionCard
            headerIcon={heartIconOrange}
            buttons={[
              {
                buttonStyle: "primary",
                buttonAction: () => exploreFlights(),
                text: "Explore Flights",
              },
            ]}
            principalText="Oops! You don't have any favorites yet."
            secondaryText={`Save flights to your favorites and access them easily later!`}
          />
        )}
        {flightsList?.map((flight, index) => {
          const seatType = [
            flight.YAvailable ? "economic" : null,
            flight.WAvailable ? "premium" : null,
            flight.JAvailable ? "executive" : null,
            flight.FAvailable ? "first" : null,
          ].filter(Boolean) as ClassesFlightsType[];

          return (
            <div key={`${flight.id} - ${index}`}>
              <AirlinesSimpleTickets
                isANotifiedFlight={alertList.includes(flight.id)}
                isALikedFlight={flight.isFavorite}
                isSelected={selectedFlightId === flight.id}
                cardClick={(id) => selectFlightHandler(id, flight)}
                ticket={{
                  id: flight.id,
                  price: flight.YMileageCost,
                  seatType,
                  destination: {
                    airport: flight.route.destinationAirport,
                    date: flight.date,
                    time: flight.date,
                  },
                  origin: {
                    airport: flight.route.originAirport,
                    date: flight.date,
                    time: flight.date,
                  },
                }}
              />
            </div>
          );
        })}
      </main>
    </div>
  );
};

export default FavoriteFliesRoutesPage;
