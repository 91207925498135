import fetchApi from "../fetchApi";

export type GetFlightsQueryParams = {
  origin?: string;
  destination?: string;
  source?: string;
  take?: number;
  skip?: number;
  classes?: ("Y" | "W" | "J" | "F")[];
  direct?: boolean;
  date?: string;
};

const getFlights = async (
  token: string,
  queryParams?: Partial<GetFlightsQueryParams>
) => {
  const params: Record<string, any> = {};

  if (queryParams?.destination || queryParams?.destination !== undefined) {
    params.destination = queryParams?.destination;
  }

  if (queryParams?.source || queryParams?.source !== undefined) {
    params.source = queryParams?.source;
  }

  if (queryParams?.date || queryParams?.date !== undefined) {
    params.date = queryParams?.date;
  }

  if (queryParams?.direct || queryParams?.direct !== undefined) {
    params.direct = queryParams?.direct;
  }

  if (queryParams?.classes || queryParams?.classes !== undefined) {
    params.classes = queryParams?.classes;
  }

  if (queryParams?.take || queryParams?.take !== undefined) {
    params.take = queryParams?.take;
  }

  if (queryParams?.skip || queryParams?.skip !== undefined) {
    params.skip = queryParams?.skip;
  }

  if (queryParams?.origin || queryParams?.origin !== undefined) {
    params.origin = queryParams?.origin;
  }

  return await fetchApi({
    service: "flights",
    endPoint: ``,
    method: "GET",
    token,
    params,
  });
};

export default getFlights;
