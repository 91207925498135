import fetchApi from "../fetchApi";

const deleteUserId = async (id: string) => {
  return await fetchApi({
    service: "users",
    endPoint: `/${id}`,
    method: "DELETE",
  });
};

export default deleteUserId;
