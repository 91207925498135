import { useState } from "react";
import Header from "../../components/Headers/Header";
import "./styles.scss";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import { IToast } from "../../types/toast";
import Toast from "../../components/Toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoginSignUpNavigation } from "contexts/useLoginSignUpNavigation";
import postResetPassword from "api/auth/postResetPassword";
import { useModal } from "contexts/useModal";
import CheckCircleGreen from "../../assets/icons/checks/check-circle-green.svg";
import Loading from "components/Loading";

const ResetPasswordEmailSenderPage: React.FC = () => {
  const [currentEmail, setCurrentEmail] = useState("");
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const { setModalContent, setOpenUseModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [emailInputWarning, setEmailInputWarning] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenParams = queryParams.get("token");
  const navigate = useNavigate();
  const { setComponent } = useLoginSignUpNavigation();

  const emailSendSuccessfully = (
    <section className="reset-email-modal-content">
      <img src={CheckCircleGreen} alt="check" className="status-request" />

      <h2 className="email-verify-content-modal__title">Success!</h2>
      <p className="reset-email-modal-content__explanation-text">
        If you have an AirFly account, we’ve sent you a password reset link.
      </p>
      <p className="reset-email-modal-content__explanation-text">
        Take a look at your email inbox, and don’t forget to check the spam
        folder just in case!
      </p>
    </section>
  );

  const resetPasswordSubmit = async () => {
    setIsLoading(true);

    if (currentEmail.length < 5) {
      setToastShow(true);
      setEmailInputWarning(true);

      setToastConfig({
        description: "You need to add your email to continue",
        title: "Email is empty",
        type: "info",
      });

      setTimeout(() => {
        setEmailInputWarning(false);
        setToastShow(false);
      }, 4000);

      return;
    }
    if (!currentEmail.includes("@") || !currentEmail.includes(".com")) {
      setToastShow(true);
      setEmailInputWarning(true);

      setToastConfig({
        description: "Add a valid email to continue.",
        title: "Invalid Email",
        type: "info",
      });

      setTimeout(() => {
        setEmailInputWarning(false);
        setToastShow(false);
      }, 4000);

      return;
    }
    try {
      const request = await postResetPassword({ email: currentEmail });

      switch (request.status) {
        case 200:
          setComponent(emailSendSuccessfully);

          setTimeout(() => {
            setComponent(<ResetPasswordEmailSenderPage />);
          }, 7000);
          break;

        default:
          setToastConfig({
            description:
              "We had a problem making the request. Please try again",
            title: "Sorry",
            type: "error",
          });

          setToastShow(true);
          setTimeout(() => {
            setToastShow(false);
          }, 4000);
          break;
      }
    } catch (error) {
      console.error(error);

      setToastConfig({
        description: "We had a problem making the request. Please try again",
        title: "Sorry",
        type: "error",
      });

      setToastShow(true);
      setTimeout(() => {
        setToastShow(false);
      }, 4000);
    }
    setIsLoading(false);
  };

  const cancelSubmit = () => {
    setComponent("/login");
    if (tokenParams) {
      navigate("/");
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="reset-password">
      <Header
        headerIcon="back-button"
        backButtonClick={() => setComponent("/login")}
        title={"Reset Password"}
        subTitle={`Enter your email, we will send you a link to 
							reset your password`}
      />
      <InputText
        value={currentEmail}
        placeholder="Your email"
        onChangeInput={setCurrentEmail}
        inputType="text"
        className="margin-top-16"
        isWarning={emailInputWarning}
      />

      <SubText altIcon="clock icon">
        Never share your credentials with anyone.
      </SubText>

      <div className="buttons-container">
        <Button buttonStyle="primary" onClick={resetPasswordSubmit}>
          Reset password
        </Button>

        <Button buttonStyle="quaternary" onClick={cancelSubmit}>
          Cancel
        </Button>
      </div>

      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default ResetPasswordEmailSenderPage;
