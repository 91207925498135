import React from "react";
import ReactDOM from "react-dom/client";
import "../src/scss/index.scss";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import Contexts from "contexts";
import ErrorBoundary from "utils/errorBoundary";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Contexts>
        <RouterProvider router={router} />
      </Contexts>
    </ErrorBoundary>
  </React.StrictMode>
);
