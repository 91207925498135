import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import InputText from "../../Inputs/InputText";
import ChevronDown from "../../../assets/icons/arrows/bx-chevron-down.svg";
import ChevronUp from "../../../assets/icons/arrows/bx-chevron-up.svg";
import InputRadio from "../../Inputs/InputRadio";
import InputCheckbox from "../../Inputs/InputCheckbox";

interface DropDownProps {
  className?: string;
  options: DropDownRadioElement[];
  setValueCallBack: (option: string[]) => void;
  placeholder?: string;
  iconLeft?: string;
  label?: string;
  textButton?: string;
  dropType: "radio" | "checkbox";
  buttonCallBack?: () => void;
  defaultValueProp?: string | string[] | undefined;
  readOnly?: boolean;
  showInputRadio?: boolean;
  hasIconRightButton?: boolean;
}

interface DropDownRadioElement {
  inputLabel: string;
  inputValue: string;
  groupName: string;
}

const DropDown: React.FC<DropDownProps> = (props) => {
  const {
    className,
    options,
    setValueCallBack,
    placeholder,
    label,
    dropType,
    defaultValueProp,
    readOnly,
    showInputRadio,
    hasIconRightButton,
    // textButton,
    // buttonCallBack,
    // iconLeft
  } = props;

  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [inputValue, setInputValue] = useState<string | string[]>("");
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropDownRef.current &&
      !dropDownRef.current.contains(event.target as Node)
    ) {
      setIsOpenDropDown(false);
    }
  };

  const toggleOpenDropDown = () => {
    setIsOpenDropDown((prev) => !prev);
  };

  const selectOption = (option: string[]) => {
    setInputValue(option);
    setValueCallBack(option);
    setIsOpenDropDown(false);
  };

  const getDisplayValue = () => {
    if (dropType === "checkbox" && Array.isArray(inputValue)) {
      return inputValue.join(", ");
    }
    return typeof inputValue === "string" ? inputValue : inputValue[0];
  };

  const [isTransitionComplete, setIsTransitionComplete] = useState(false);

  useEffect(() => {
    const element = inputRadioRef.current;
    let transitionsCompleted = 0;

    if (!element) return;

    const handleTransitionEnd = () => {
      transitionsCompleted += 1;

      const transitionProperties =
        getComputedStyle(element).transitionProperty.split(", ").length;
      if (transitionsCompleted >= transitionProperties) {
        setIsTransitionComplete(true);
        transitionsCompleted = 0;
      } else {
        setIsTransitionComplete(false);
      }
    };

    element.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      element.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, []);

  const inputRadioRef = useRef<HTMLDivElement>(null);
  const inputCheckboxRef = useRef<HTMLDivElement>(null);

  return (
    <div
      id="drop-down-component"
      className={className ?? ""}
      data-active={isOpenDropDown}
      ref={dropDownRef}
    >
      <InputText
        label={label}
        value={getDisplayValue()}
        onChangeInput={(e) => getDisplayValue()}
        onClick={toggleOpenDropDown}
        inputType="text"
        className={`drop-down-component__input`}
        placeholder={placeholder}
        iconRight={isOpenDropDown ? ChevronUp : ChevronDown}
        textTransform={"capitalize"}
        readOnly={readOnly}
        hasIconRightButton={hasIconRightButton}
        onClickIconRight={toggleOpenDropDown}
      />
      <div
        ref={inputRadioRef}
        className={`drop-down-component__input-container ${
          isOpenDropDown
            ? "drop-down-component__input-container-open"
            : "drop-down-component__input-container-close"
        }`}
      >
        {dropType === "radio" ? (
          <InputRadio
            elements={options}
            onChangeValueCallback={selectOption}
            selectedValue={defaultValueProp}
            className={` ${isTransitionComplete ? "active" : ""}`}
            showInputRadio={showInputRadio}
          />
        ) : dropType === "checkbox" ? (
          <InputCheckbox
            elements={options}
            onChangeValueCallback={selectOption}
            className={`padding-top-24`}
            defaultValueProp={defaultValueProp as string[]}
          />
        ) : null}
      </div>
    </div>
  );
};

export default DropDown;
