import React, { useState, useRef, useEffect } from "react";
import "./styles.scss";

interface SelectProps {
  options: IOptionSelectProps[];
  defaultValueText?: string;
  labelSelect?: string;
  iconLeft?: string;
  onChange: (a: string) => void;
  isWarning?: boolean;
  disabled?: boolean;
  className?: string;
  titleText?: string;
}

export interface IOptionSelectProps {
  label: string;
  value: string;
  disabled?: boolean;
  optionImage?: string;
}

const Select: React.FC<SelectProps> = (props) => {
  const {
    options,
    defaultValueText = "-",
    labelSelect,
    iconLeft,
    onChange,
    isWarning,
    disabled,
    className,
    titleText,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] =
    useState<IOptionSelectProps | null>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    if (options.length <= 1) return;
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option: IOptionSelectProps) => {
    setSelectedOption(option);
    onChange(option.value);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (defaultValueText === "-") {
      setSelectedOption(null);
    } else {
      const matchingOption = options.find(
        (option) => option.value === defaultValueText
      );

      if (matchingOption) {
        setSelectedOption(matchingOption);
      }
    }
  }, [defaultValueText]);

  return (
    <div ref={dropdownRef} id="select-dropdown" className={className ?? ""}>
      {/* {titleText && (
        <p className={`select-title ${disabled ? "disabled-label" : ""}`}>
          {titleText}
        </p>
      )} */}
      <p className={`select-label`}>{labelSelect}</p>
      <div
        onClick={toggleDropdown}
        id="select-default"
        className={`${isWarning ? "isWarning" : ""} ${
          disabled ? "disabled-select" : ""
        }`}
      >
        <div className="dropdown-header">
          {iconLeft && <img src={iconLeft} alt="icon select" />}
          <p
            className={`no-margin ${
              !selectedOption ? "placeholder-likes" : ""
            }`}
          >
            {selectedOption ? selectedOption.label : defaultValueText}
          </p>
        </div>
        {isOpen && !disabled && (
          <div className="dropdown-wrapper">
            <ul className="dropdown-list no-padding">
              {options.map((option, index) => (
                <li
                  className={`${option.disabled ? "li-disabled" : ""}`}
                  key={index}
                  onClick={() => {
                    !option.disabled && handleOptionSelect(option);
                  }}
                >
                  {option.optionImage && (
                    <img
                      className="option-image"
                      src={option.optionImage}
                      alt={`option ${index + 1}`}
                    />
                  )}
                  <p className="no-margin label-drop-down">{option.label}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Select;
