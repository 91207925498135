import fetchApi from "../fetchApi";

export type PostWaitListBody = {
  email: string;
  newsLetter?: boolean;
  consentGiven: boolean;
};

const postWaitList = async (data: PostWaitListBody) => {
  return await fetchApi({
    service: "waitlists",
    endPoint: ``,
    method: "POST",
    data,
  });
};

export default postWaitList;
