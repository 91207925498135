import { createBrowserRouter } from "react-router-dom";
import AccountLoginPage from "../pages/AccountLogin";
import CreateAccountPage from "pages/CreateAccount";
import SearchMilesyPage from "pages/SearchMilesy";
import LoadingPage from "pages/Splash";
import FlightDetailsPage from "pages/FlightDetails";
import UserSettingsPage from "pages/UserSettings";
import UserSettingsAccountPage from "pages/UserSettingsAccount";
import UserSettingsProfilePage from "pages/UserSettingsProfile";
import UserSettingsSubscriptionPage from "pages/UserSettingsSubscription";
import App from "pages/App";
import HomePage from "pages/HomePage";
import UserSettingsChangePasswordPage from "pages/UserSettingsChangePassword";
import UserSettingsEditProfilePage from "pages/UserSettingsEditProfile";
import UserSettingsManageAccountPage from "pages/UserSettingsManageAccount";
import UserSettingsPrivacyPage from "pages/UserSettingsPrivacy";
import UserSettingsAppLanguagePage from "pages/UserSettingsAppLanguage";
import FavoriteFliesRoutesPage from "pages/FavoriteRoutes";
import MyAlertsPage from "pages/MyAlerts";
import RankingPage from "pages/Ranking";
import AirportQuizPage from "pages/AirportQuiz";
import LandingPage from "pages/LandingPage";
import VerifyEmailPage from "pages/VerifyEmail";

import ResetPasswordEmailSenderPage from "pages/ResetPassword";
import ResetPasswordEmailLink from "pages/ResetPassword/ResetPasswordEmailLink";
import AddPhoneNumberPage from "pages/AddPhoneNumber";
import DetailsFlightByLinkPage from "pages/DetailsFlightByLink";
import FlightDetailsPageLink from "pages/DetailsFlightByLink/FlightDetailsPageLink";
import UserSettingsAlertsPage from "pages/UserSettingsAlerts";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/app",
    element: <App />,
  },
  {
    path: "/create-account",
    element: <CreateAccountPage />,
  },
  {
    path: "/login",
    element: <AccountLoginPage />,
  },
  {
    path: "/reset-password-email-sender",
    element: <ResetPasswordEmailSenderPage />,
  },

  {
    path: "/reset-password",
    element: <ResetPasswordEmailLink />,
  },
  {
    path: "/home",
    element: <HomePage />,
  },
  { path: "/home/share", element: <DetailsFlightByLinkPage /> },
  {
    path: "/search-milesy",
    element: <SearchMilesyPage />,
  },
  {
    path: "/flight-details",
    element: <FlightDetailsPage />,
  },
  {
    path: "/flight-details/link",
    element: <FlightDetailsPageLink />,
  },
  {
    path: "/user-settings",
    element: <UserSettingsPage />,
  },
  {
    path: "/user-settings-profile",
    element: <UserSettingsProfilePage />,
  },
  {
    path: "/user-settings-account",
    element: <UserSettingsAccountPage />,
  },
  {
    path: "/user-settings-subscriptions",
    element: <UserSettingsSubscriptionPage />,
  },
  {
    path: "/user-settings-change-password",
    element: <UserSettingsChangePasswordPage />,
  },
  {
    path: "/user-settings-edit-profile",
    element: <UserSettingsEditProfilePage />,
  },
  {
    path: "/user-settings-language",
    element: <UserSettingsAppLanguagePage />,
  },
  {
    path: "/user-settings-manage-account",
    element: <UserSettingsManageAccountPage />,
  },
  {
    path: "/user-settings-privacy",
    element: <UserSettingsPrivacyPage />,
  },
  {
    path: "/add-phone-number",
    element: <AddPhoneNumberPage />,
  },
  {
    path: "/favorites-flies",
    element: <FavoriteFliesRoutesPage />,
  },
  {
    path: "/my-alerts",
    element: <MyAlertsPage />,
  },
  {
    path: "/ranking",
    element: <RankingPage />,
  },
  {
    path: "/airport-quiz",
    element: <AirportQuizPage />,
  },
  {
    path: "/verify-email",
    element: <VerifyEmailPage />,
  },
  {
    path: "/user-settings-alerts",
    element: <UserSettingsAlertsPage />,
  },

  // {
  //   path: "/search-milesy",
  //   element: <DestinationPage />,
  // },
  // {
  //   path: "/airlines-tickets",
  //   element: <AirlinesTicketsAvailablePage />,
  // },
  // {
  //   path: "/travel-selected",
  //   element: <TravelSelectedPage />,
  // },
  // {
  //   path: "/travel-purchased",
  //   element: <TravelPurchasedPage />,
  // },
]);
