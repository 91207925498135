import { ReactNode, useContext, useState, createContext } from "react";

interface IOriginDestiny {
  origin: { iata?: string; lat: number; lon: number };
  destiny: {
    iata?: string;
    lat: number;
    lon: number;
    classes?: ("Y" | "W" | "J" | "F")[];
    direct?: boolean;
    date?: string;
  };
}

interface ISearchOriginDestinyProps {
  setOriginDestiny: React.Dispatch<React.SetStateAction<IOriginDestiny>>;
  originDestiny: IOriginDestiny;
}

const SearchOriginDestinyContext = createContext<ISearchOriginDestinyProps>(
  {} as ISearchOriginDestinyProps
);

const SearchOriginDestinyProvider = ({ children }: { children: ReactNode }) => {
  const [originDestiny, setOriginDestiny] = useState<IOriginDestiny>({
    origin: { iata: "", lat: 0, lon: 0 },
    destiny: { iata: "", lat: 0, lon: 0 },
  });

  return (
    <SearchOriginDestinyContext.Provider
      value={{ setOriginDestiny, originDestiny }}
    >
      {children}
    </SearchOriginDestinyContext.Provider>
  );
};

const useSearchOriginDestiny = () => {
  const context = useContext(SearchOriginDestinyContext);
  if (!context) {
    throw new Error(
      "useSearchOriginDestiny must be used with a SearchOriginDestinyProvider"
    );
  }

  return context;
};

export { useSearchOriginDestiny, SearchOriginDestinyProvider };
