import fetchApi from "../fetchApi";

export type PostUsersChangePasswordBody = {
  oldPassword: string;
  newPassword: string;
};

const postChangePassword = async (
  token: string,
  data: PostUsersChangePasswordBody
) => {
  return await fetchApi({
    service: "users",
    endPoint: `/change-password`,
    method: "POST",
    token,
    data,
  });
};

export default postChangePassword;
