import React from "react";
import "./styles.scss";

interface ListItemRankingProps {
  position?: string;
  profileImage?: string;
  title?: string;
  desc?: string;
  secondaryText?: string | number;
  rightNodeContent?: React.ReactNode;
  hasSeparator?: boolean;
  className?: string;
  onClick?: (a: any) => void;
}

const ListItemRanking: React.FC<ListItemRankingProps> = (props) => {
  const {
    position,
    profileImage,
    title,
    desc,
    secondaryText,
    rightNodeContent,
    hasSeparator,
    className,
    onClick,
  } = props;

  return (
    <div className={`list-item-ranking ${className ?? ""}`} onClick={onClick}>
      {position && <span className="list-item-position">{position}</span>}

      {profileImage && <span className="list-item-avatar">{profileImage}</span>}

      <div className="list-item-main">
        {title && <p className="list-item-main-top no-margin">{title}</p>}
        {desc && <span className="list-item-main-bot">{desc}</span>}
      </div>

      {secondaryText && <p className="list-item-secondary">{secondaryText}</p>}

      {rightNodeContent && (
        <section className="list-item-extra">{rightNodeContent}</section>
      )}

      {hasSeparator && <div className="separator" />}
    </div>
  );
};

export default ListItemRanking;
