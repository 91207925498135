import React, { ReactNode } from "react";
import "./styles.scss";

interface TitleTextProps {
  children: ReactNode;
  size?: "h1" | "h2" | "h3" | "h4" | "title";
  titleClass?: string;
  textAlign?: "left" | "right" | "center";
}

const TitleText: React.FC<TitleTextProps> = ({
  children,
  size = "h3",
  titleClass,
  textAlign,
}) => {
  const Tag = size === "title" ? "p" : size;
  return (
    <Tag id="title-text" className={titleClass ?? ""} style={{ textAlign }}>
      {children}
    </Tag>
  );
};

export default TitleText;
