import fetchApi from "../fetchApi";

const postVerifyCode = async (
  token: string,
  data: { phone: string; code: string }
) => {
  return await fetchApi({
    service: "users",
    endPoint: `/confirm-phone-code`,
    method: "POST",
    token,
    data,
  });
};

export default postVerifyCode;
