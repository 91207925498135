import fetchApi from "../fetchApi";

export interface PostUserAlertsBodyType {
  origin: string;
  destination: string;
  date: Date | string;
  classes: string[] | undefined;
  direct: boolean | undefined;
}

const postUserAlerts = async (
  token: string,
  dataBody: PostUserAlertsBodyType
) => {
  const data = Object.fromEntries(
    Object.entries(dataBody).filter(([_, value]) => value !== undefined)
  );

  return await fetchApi({
    service: "alerts",
    endPoint: "",
    method: "POST",
    token,
    data,
  });
};

export default postUserAlerts;
