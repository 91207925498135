import React, { useEffect, useState } from "react";
import "./styles.scss";

interface LinearTimerProps {
  duration: number;
  stopTimer?: boolean;
  resetTimer?: boolean;
  className?: string;
  onChange?: (currentTime: number) => void;
  onTimeEnd?: () => void;
}

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${minutes}:${secs.toString().padStart(2, "0")}`;
};

const LinearTimer: React.FC<LinearTimerProps> = ({
  duration,
  stopTimer = false,
  resetTimer = false,
  className,
  onChange,
  onTimeEnd,
}) => {
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    if (stopTimer) {
      return;
    }

    if (resetTimer) {
      setCurrentTime(0);
    }

    const timer = setInterval(() => {
      setCurrentTime((prev) => {
        const nextTime = prev + 1;
        if (onChange) onChange(nextTime);
        if (nextTime >= duration) {
          clearInterval(timer);
          onTimeEnd && onTimeEnd();
        }
        return nextTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [duration, onChange, stopTimer]);

  const progress = (currentTime / duration) * 100;

  return (
    <div className={`linear-timer ${className || ""}`}>
      <div
        className={`linear-timer__circle ${
          currentTime > 0 ? "linear-timer--active" : ""
        }`}
      />
      <div className="linear-timer__bar">
        <div
          className="linear-timer__progress"
          style={{
            width: `${progress}%`,
            transition: `${
              !stopTimer ? "width .25s linear" : "width 1s linear"
            }`,
          }}
        />
      </div>
      <div
        className={`linear-timer__square ${
          currentTime >= duration ? "linear-timer--completed" : ""
        }`}
      />
      <div
        className={`linear-timer__time ${
          currentTime >= duration ? "linear-timer__time--completed" : ""
        } `}
      >
        {formatTime(currentTime)}
      </div>
    </div>
  );
};

export default LinearTimer;
