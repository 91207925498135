import React, { useEffect, useState } from "react";
import "./styles.scss";

import Loading from "components/Loading";
import PageTitle from "components/PageTitles";
import AirlinesQuizCard, { IAnswer } from "components/Cards/AirlinesQuizCard";

import { useDeskNavigation } from "contexts/useDeskNavigation";
import { useUserInfo } from "contexts/userInfo";
import { useIconContext } from "contexts/useIconNav";

import getGameQuestion from "api/game/getGameQuestion";
import postGameAnswer from "api/game/postGameAnswer";

import { thousandsFormat } from "utils/thousandsFormat";
import getAirportsCodes from "api/flights/getAirportsCodes";

interface AnswerChoice {
  iata: string;
}

const AirportQuizPage: React.FC = () => {
  const {
    setMainScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();
  const { handleButtonClick } = useIconContext();
  const [profileAvatar, setProfileAvatar] = useState("");
  const [answersQuiz, setAnswersQuiz] = useState<IAnswer[]>([]);
  const [windowWidthSize, settWidthSize] = useState(window.innerWidth);
  const [totalAirports, setTotalAirports] = useState(0);
  useEffect(() => {
    window.addEventListener("resize", () => {
      settWidthSize(window.outerWidth);
    });
  }, [window.outerWidth]);

  useEffect(() => {
    const fetchTotalAirports = async () => {
      const total = await getTotalAirports();
      setTotalAirports(total);
    };

    fetchTotalAirports();
  }, []);

  const getTotalAirports = async () => {
    const { res } = await getAirportsCodes(userInfo["@air-fly_access-token"]);
    return res.total;
  };

  const [gameState, setGameState] = useState({
    questionNumber: 0,
    gameQuestion: "",
    gameOptions: [] as AnswerChoice[],
    gameScore: 0,
    hasAnswered: false,
    isGameFinished: false,
  });

  const [uiState, setUiState] = useState({
    loading: true,
    isFirstLoad: true,
    timerToAnswer: 10,
    stopTimer: false,
    resetTimer: false,
    limitAnswered: 0,
  });

  const endQuestion = totalAirports;
  const { userInfo } = useUserInfo();

  useEffect(() => {
    setProfileAvatar(userInfo["@air-fly_user_avatar"]);
  }, []);

  const fetchGameQuestion = async () => {
    const { res } = await getGameQuestion(userInfo["@air-fly_access-token"]);
    const { questionNumber, question, score } = res;

    setGameState((prev) => ({
      ...prev,
      questionNumber,
      gameQuestion: question.airportName,
      gameOptions: question.answerChoices,
      gameScore: score,
      hasAnswered: false,
    }));

    setUiState((prev) => ({
      ...prev,
      loading: false,
      stopTimer: false,
      resetTimer: true,
      timerToAnswer: 10,
    }));
  };

  useEffect(() => {
    if (!gameState.isGameFinished) {
      fetchGameQuestion();
    }
  }, []);

  useEffect(() => {
    if (gameState.gameOptions.length > 0) {
      const formattedAnswers: IAnswer[] = gameState.gameOptions.map(
        (choice) => ({
          iata: choice.iata,
          answerStatus: "non-selected",
        })
      );
      setAnswersQuiz(formattedAnswers);
    }
  }, [gameState.gameOptions]);

  const clickAnswerHandler = async (selectedAnswer: string) => {
    if (gameState.hasAnswered || uiState.limitAnswered >= endQuestion) return;

    setGameState((prev) => ({ ...prev, hasAnswered: true }));

    let isCorrect = false;

    if (selectedAnswer === "times_up") {
      const response = await postGameAnswer(userInfo["@air-fly_access-token"], {
        answer: "times_up",
      });
      isCorrect = response.res.isCorrect;
    } else {
      const updatedAnswers = await Promise.all(
        answersQuiz.map(async (item) => {
          if (item.iata === selectedAnswer) {
            const response = await postGameAnswer(
              userInfo["@air-fly_access-token"],
              { answer: selectedAnswer }
            );
            isCorrect = response.res.isCorrect;
            return isCorrect
              ? { ...item, answerStatus: "correct" as "correct" }
              : { ...item, answerStatus: "wrong" as "wrong" };
          }
          return { ...item, answerStatus: "non-selected" as "non-selected" };
        })
      );

      setAnswersQuiz(updatedAnswers);
    }

    setUiState((prev) => ({
      ...prev,
      hasAnswered: true,
      stopTimer: true,
      resetTimer: false,
      limitAnswered: prev.limitAnswered + 1,
    }));

    const isGameFinished = uiState.limitAnswered >= endQuestion - 1;

    if (isGameFinished) {
      setGameState((prev) => ({
        ...prev,
        isGameFinished: true,
      }));
    } else {
      setTimeout(fetchGameQuestion, 1500);
    }
  };

  const flyAgainHandler = () => {
    setUiState((prev) => ({
      ...prev,
      limitAnswered: 0,
      timerToAnswer: 10,
    }));
    setGameState((prev) => ({
      ...prev,
      isGameFinished: false,
    }));
    fetchGameQuestion();
  };

  const stopPlayingHandler = () => {
    setMainScreen("/MapRoute");
    setPrincipalScreen("/search-milesy");
  };

  const leaderBoardHandler = () => {
    setMainScreen("/ranking");
  };

  const handleTimerEnd = async (): Promise<void> => {
    clickAnswerHandler("times_up");
  };

  const closeQuiz = () => {
    setMainScreen("");
    setPrincipalScreen("/search-milesy");
    setSecondaryScreen("");
    setTertiaryScreen("");
    handleButtonClick("grid");
  };

  return (
    <div className="airport-quiz-page">
      <section className="airport-quiz-page__header-wrapper">
        <PageTitle
          className="padding-hor-24"
          buttonFlagAction={leaderBoardHandler}
          buttonFlag
          rightButtonWhite={windowWidthSize >= 1024}
          buttonWhite={windowWidthSize <= 1024}
          title="Airport QWZ"
          subtitle="Test your knowledge"
          onBackClick={closeQuiz}
          chalkBackGround={windowWidthSize <= 1024}
        />
      </section>

      <main className="airport-quiz-page__main">
        {uiState.loading ? (
          <div className="airport-quiz-loading">
            <Loading />
          </div>
        ) : (
          <AirlinesQuizCard
            question={gameState.isGameFinished ? "" : gameState.gameQuestion}
            airportNumber={gameState.questionNumber}
            airportPoints={thousandsFormat(totalAirports)}
            pointsCorrect
            pointsPoints={gameState.gameScore}
            congratulations={gameState.isGameFinished}
            answers={answersQuiz}
            timer={uiState.timerToAnswer}
            stopTimer={uiState.stopTimer}
            resetTimer={uiState.resetTimer}
            className="padding-hor-16"
            clickAnswer={clickAnswerHandler}
            clickFlyAgain={flyAgainHandler}
            clickStopPlaying={stopPlayingHandler}
            onTimeEnd={handleTimerEnd}
          />
        )}
      </main>
    </div>
  );
};

export default AirportQuizPage;
