import { useEffect, useState } from "react";
import Header from "../../../components/Headers/Header";
import "./styles.scss";
import InputText from "../../../components/Inputs/InputText";
import Button from "../../../components/Buttons/Button";
import SubText from "../../../components/Texts/SubText";
import { IToast } from "../../../types/toast";
import Toast from "../../../components/Toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoginSignUpNavigation } from "contexts/useLoginSignUpNavigation";
import postResetPassword from "api/auth/postResetPassword";
import { useModal } from "contexts/useModal";
import CheckCircleGreen from "../../../assets/icons/checks/check-circle-green.svg";
import ErrorCheck from "../../../assets/icons/close/close-icon-pink.svg";
import Loading from "components/Loading";
import LogInSignUpDesk from "components/Wrappers/LoginSignUpDesk";
import postUpdatePassword from "api/auth/postUpdatePassword";
import PasswordStrengthBar from "react-password-strength-bar";

const ResetPasswordEmailLink: React.FC = () => {
  const [newUserPassword, setNewUserPassword] = useState("");
  const [newUserConfirmPassword, setNewUserConfirmPassword] = useState("");
  const [newUserPasswordWarning, setNewUserPasswordWarning] = useState(false);
  const [newUserConfirmPasswordWarning, setNewUserConfirmPasswordWarning] =
    useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const { openUseModal, setModalContent, setOpenUseModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const [isResetPassEmailSended, setIsResetPassEmailSended] = useState(false);
  const { setComponent } = useLoginSignUpNavigation();
  const [tokenSearchParams, setTokenSearchparams] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    if (token) {
      setTokenSearchparams(token);
    } else {
      setComponent("/reset-password-email-sender");
    }
  }, [window.location.search]);

  const modalEmailVerifyContent = () => {
    return (
      <section className="reset-email-modal-content">
        <img src={CheckCircleGreen} alt="check" className="status-request" />

        <h2 className="email-verify-content-modal__title">
          Password modified successfully
        </h2>
        <p className="email-verify-content-modal__explanation-text">
          Log in with your new password
        </p>

        <Button
          onClick={() => {
            setOpenUseModal(false);
            navigate("/app");
          }}
          buttonStyle="primary"
        >
          Close
        </Button>
      </section>
    );
  };

  const cancelSubmit = () => {
    setComponent("/login");
  };

  const resetPasswordWithToken = async () => {
    setIsLoading(true);
    if (!newUserPassword || !newUserConfirmPassword) {
      setToastConfig({
        description: "Fill in the fields correctly",
        title: "Reset password",
        type: "info",
      });

      setNewUserConfirmPasswordWarning(true);
      setNewUserPasswordWarning(true);

      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
        setNewUserConfirmPasswordWarning(false);
        setNewUserPasswordWarning(false);
      }, 4000);
      setIsLoading(false);

      return;
    }

    if (newUserPassword.length < 8 || newUserConfirmPassword.length < 8) {
      setToastConfig({
        description: "Passwords must be at least 8 characters long",
        title: "Reset password",
        type: "info",
      });

      setNewUserConfirmPasswordWarning(true);
      setNewUserPasswordWarning(true);

      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
        setNewUserConfirmPasswordWarning(false);
        setNewUserPasswordWarning(false);
      }, 4000);
      setIsLoading(false);

      return;
    }

    if (newUserPassword !== newUserConfirmPassword) {
      setToastConfig({
        description: "Passwords are not the same",
        title: "Reset password",
        type: "info",
      });

      setNewUserConfirmPasswordWarning(true);
      setNewUserPasswordWarning(true);

      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
        setNewUserConfirmPasswordWarning(false);
        setNewUserPasswordWarning(false);
      }, 4000);
      setIsLoading(false);

      return;
    }

    try {
      const request = await postUpdatePassword({
        token: tokenSearchParams,
        newPassword: newUserConfirmPassword,
      });

      switch (request.status) {
        case 200:
          setModalContent(modalEmailVerifyContent());
          setOpenUseModal(true);
          break;
        case 401:
          setToastConfig({
            description:
              "this link has expired. Please repeat the password reset process",
            title: "Sorry",
            type: "error",
          });

          setToastShow(true);
          setTimeout(() => {
            setToastShow(false);
            setComponent("/reset-password-email-sender");
          }, 4000);
          break;
        default:
          setToastConfig({
            description:
              "We had a problem making the request. Please try again",
            title: "Sorry",
            type: "error",
          });

          setToastShow(true);
          setTimeout(() => {
            setToastShow(false);
          }, 4000);
          break;
      }
    } catch (error) {
      console.error(error);
      setToastConfig({
        description: "We had a problem making the request. Please try again",
        title: "Sorry",
        type: "error",
      });

      setToastShow(true);
      setTimeout(() => {
        setToastShow(false);
      }, 4000);
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <LogInSignUpDesk
        component={
          <div className="reset-password">
            <Header
              headerIcon="back-button"
              backButtonClick={() => setComponent("/login")}
              title="Reset Password"
              subTitle={
                <>
                  Your password should be at least <br /> 8 characters long
                </>
              }
            />

            <InputText
              value={newUserPassword}
              placeholder="New Password"
              onChangeInput={setNewUserPassword}
              inputType="password"
              isWarning={newUserPasswordWarning}
              className="margin-vert-16"
            />

            <InputText
              value={newUserConfirmPassword}
              placeholder="Confirm New Password"
              onChangeInput={setNewUserConfirmPassword}
              inputType="password"
              isWarning={newUserConfirmPasswordWarning}
            />
            <PasswordStrengthBar
              className="margin-vert-16"
              minLength={8}
              barColors={[
                "#dddddd",
                "var(--peach)",
                "#FFC045",
                "var(--green)",
                "var(--green",
              ]}
              scoreWords={[
                "(add more characters to strengthen) very weak",
                "(add more characters to strengthen) weak",
                "good",
                "strong",
                "very strong",
              ]}
              scoreWordClassName="text-password-bar"
              shortScoreWord="Your password should be at least 8 characters long"
              password={newUserPassword}
            />
            <SubText>Never share your credentials with anyone.</SubText>
            <div className="buttons-container">
              <Button buttonStyle="primary" onClick={resetPasswordWithToken}>
                Reset password
              </Button>
              <Button buttonStyle="quaternary" onClick={cancelSubmit}>
                Cancel
              </Button>
            </div>
          </div>
        }
      />
      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </>
  );
};

export default ResetPasswordEmailLink;
