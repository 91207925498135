import fetchApi from "../fetchApi";

const createUser = async (data: {
  displayName: string;
  email: string;
  password: string;
}) => {
  return await fetchApi({
    service: "auth",
    endPoint: "/register",
    method: "POST",
    data,
  });
};

export default createUser;
