import React, { ReactNode } from "react";
import "./styles.scss";
import flyRoute from "../../../assets/images/fly.png";
import splashPageApp from "../../../assets/images/Smartphone-1.png";
import searchMilesPage from "../../../assets/images/Smartphone-0.png";
import ticket from "../../../assets/images/Ticket.png";

import banner01 from "../../../assets/images/loginCarousel/airfly-banner_01.png";
import banner02 from "../../../assets/images/loginCarousel/airfly-banner_02.png";
import banner03 from "../../../assets/images/loginCarousel/airfly-banner_03.png";
import banner04 from "../../../assets/images/loginCarousel/airfly-banner_04.png";
import banner05 from "../../../assets/images/loginCarousel/airfly-banner_05.png";
import banner06 from "../../../assets/images/loginCarousel/airfly-banner_06.png";
import banner07 from "../../../assets/images/loginCarousel/airfly-banner_07.png";

import FadeCarousel, { Slides } from "components/FadeCarousel";

interface LogInSignUpDeskProps {
  component: ReactNode;
  isLandingPage?: boolean;
}

const LogInSignUpDesk: React.FC<LogInSignUpDeskProps> = (props) => {
  const { component, isLandingPage } = props;

  const slides: Slides[] = [
    {
      title: "Discover your next<br><strong>destination with us</strong>",
      subtitle:
        "Discover unbeatable flights and miles deals that bring your dream destinations within reach.",
      backgroundImage: banner01,
      icon: "icon-airplane",
    },
    {
      title: "Flying first-class<br><strong>never been so easy</strong>",
      subtitle:
        "With our miles-based search, finding premium seats at the best value is a breeze.",
      backgroundImage: banner02,
      icon: "icon-airplane",
    },
    {
      title: "Maximize miles,<br><strong>minimize cost</strong>",
      subtitle:
        "AirFly ensures you get the most from your miles with smart recommendations and clear booking directions.",
      backgroundImage: banner03,
      icon: "icon-airplane",
    },
    {
      title: "Travel smarter<br><strong>not harder</strong>",
      subtitle:
        "Let our intuitive platform simplify your search, so you can focus on the adventure ahead.",
      backgroundImage: banner04,
      icon: "icon-airplane",
    },
    {
      title: "Unlock the power<br><strong>of your miles</strong>",
      subtitle:
        "AirFly helps you optimize every mile, turning points into incredible travel experiences.",
      backgroundImage: banner05,
      icon: "icon-airplane",
    },
    {
      title: "Stay alert<br><strong>save more</strong>",
      subtitle:
        "Get real-time notifications on price drops and seat availability, so you never miss a deal.",
      backgroundImage: banner06,
      icon: "icon-airplane",
    },
    {
      title: "Travel smarter<br><strong>not harder</strong>",
      subtitle:
        "We give you a clear path to the best options, making travel planning straightforward and stress-free.",
      backgroundImage: banner07,
      icon: "icon-airplane",
    },
  ];

  return (
    <div id="log-in-sign-up-desk">
      <main className="log-in-desk-type">
        <div className="image-login-wrapper">
          <FadeCarousel slides={slides} duration={5000} />
        </div>
        <img src={flyRoute} alt="fly route" />
        {isLandingPage && (
          <div className="app-demo-images-container">
            <img src={splashPageApp} alt="splash app page" />
            <img src={searchMilesPage} alt="search miles app page" />
            <img src={ticket} alt="ticket trip" />
          </div>
        )}

        <div className="account-login-sign-up-page-wrapper">{component}</div>
      </main>
    </div>
  );
};

export default LogInSignUpDesk;
