import fetchApi from "../fetchApi";

const postVerifyEmailToken = async (token: string, tokenParams: string) => {
  return await fetchApi({
    service: "users",
    endPoint: `/verify-email`,
    method: "POST",
    token,
    data: { token: tokenParams },
  });
};

export default postVerifyEmailToken;
