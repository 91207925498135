import fetchApi from "../fetchApi";

const getLinkToShare = async (token: string, id?: string) => {
  return await fetchApi({
    service: "flights",
    endPoint: `/link/${id}`,
    method: "GET",
    token,
  });
};

export default getLinkToShare;
