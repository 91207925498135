import fetchApi from "../fetchApi";

const postPlans = async (token: string, planId: string) => {
  return await fetchApi({
    service: "checkout",
    endPoint: `/checkout/${planId}`,
    method: "POST",
    token,
  });
};

export default postPlans;
