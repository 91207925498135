import React, { ReactNode, useEffect, useState } from "react";
import "./styles.scss";

import PageTitle from "../../components/PageTitles";
import SlidUp from "../../components/Modal/SlidUp";
import Button from "../../components/Buttons/Button";
import ListItemLink from "../../components/Lists/ListItemLink";

import { useUserInfo } from "contexts/userInfo";
import { useDeskNavigation } from "contexts/useDeskNavigation";

import getUserInfo from "../../api/user/getUserProfile";
import patchProfileDetails from "../../api/user/patchUserProfile";

import { UserInfoRequest } from "../../types/userInfoRequest";

import { textCapitalize } from "../../utils/textCapitalize";

import ProfileIcon from "../../assets/icons/avatars/profile-icon-gray.svg";
import ListItemRadio from "components/Lists/ListItemRadio";
import InputText from "components/Inputs/InputText";
import avatarIconGray from "../../assets/icons/avatars/profile-icon-gray.svg";

interface ListDetails {
  name: string;
  profileDetailId: number;
  type: DetailsType;
}

type DetailsType =
  | "email"
  | "city"
  | "country"
  | "currentLocation"
  | "nationality"
  | "language";

const UserSettingsProfilePage: React.FC = () => {
  const [isSlidUpVisible, setIsSlidUpVisible] = useState(false);
  const [isSlidUpWriteVisible, setIsSlidUpWriteVisible] = useState(false);

  const [userInfoRequest, setUserInfoRequest] =
    useState<UserInfoRequest | null>(null);

  const [nationalityList, setNationalityList] = useState<ListDetails[] | []>(
    []
  );
  const [countryList, setCountryList] = useState<ListDetails[] | []>([]);

  const [titleSlidUp, setTitleSlidUp] = useState("");

  const [userEmail, setUserEmail] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userCountry, setUserCountry] = useState("");

  const [userProfilePicture, setUserProfilePicture] = useState("");
  const [openWriteInput, setOpenWriteInput] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<string[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>();
  const [modalSlidUpContent, setModalSlidUpContent] =
    useState<ReactNode | null>(null);
  const { setPrincipalScreen, setSecondaryScreen } = useDeskNavigation();
  const { userInfo } = useUserInfo();

  useEffect(() => {
    const requestProfileDetails = async () => {
      const response = await getUserInfo(userInfo["@air-fly_access-token"]);
    };
    requestProfileDetails();
  }, [userInfo]);

  useEffect(() => {
    requestUserInfo();
  }, [isSlidUpVisible, isSlidUpWriteVisible]);

  const requestUserInfo = async () => {
    const response = await getUserInfo(userInfo["@air-fly_access-token"]);

    // setUserProfilePicture(response.res?.photos[0]?.url || ProfileIcon);
    setUserProfilePicture(userInfo["@air-fly_user_avatar"]);

    // setUserInfoRequest(response.res);
    // const namesArray = response.res.languages.map(
    //   (language: ListDetails) => language.name
    // );
    // setSelectedLanguages(namesArray);
  };

  const changeListItemHandler = (toChange: DetailsType) => {
    let detailsSlide;

    switch (toChange) {
      case "nationality":
        detailsSlide = { list: nationalityList, title: "Nationality" };
        break;
      default:
        detailsSlide = { list: [], title: "" };
        break;
    }

    setTitleSlidUp(detailsSlide?.title);

    setIsSlidUpVisible(true);
    let valueSelected: string;
    const selectedValueHandler = (value: string) => {
      valueSelected = value;
    };
    setModalSlidUpContent(
      <>
        {detailsSlide.list?.map((detail: any) => (
          <ListItemRadio
            radioId={detail}
            groupName={toChange}
            inputValue={detail}
            isChecked={false}
            checkValueCallback={selectedValueHandler}
            highlightText={textCapitalize(detail)}
          />
        ))}

        <Button buttonStyle="primary">Apply</Button>
        <Button
          buttonStyle="secondary"
          onClick={() => setIsSlidUpVisible(false)}
        >
          Cancel
        </Button>
      </>
    );
  };

  const addSpecification = async () => {};

  const profilePictureChangeHandler = () => {};

  const personalInfoData = [
    {
      labelContent: "Profile",
      valueContent: textCapitalize(userInfo["@air-fly_display_name"]) || "Add",
      clickAction: () => setPrincipalScreen("/user-settings-edit-profile"),
    },
    {
      labelContent: "E-mail",
      valueContent: userInfo["@air-fly_user_email"].toLowerCase() || "Add",
      clickAction: () => {
        setIsSlidUpWriteVisible(true);
        setOpenWriteInput("email");
      },
    },
    {
      labelContent: "City",
      valueContent: textCapitalize(userInfo["@air-fly_user_city"]) || "Add",
      clickAction: () => {
        setIsSlidUpWriteVisible(true);
        setOpenWriteInput("city");
      },
    },
    {
      labelContent: "Country",
      valueContent: userInfo["@air-fly_user_country"] || "Add",
      clickAction: () => {
        setIsSlidUpWriteVisible(true);
        setOpenWriteInput("country");
      },
    },
    {
      labelContent: "Current Location",
      valueContent:
        `
          ${userInfo["@air-fly_user_country"]}  
          ${textCapitalize(userInfo["@air-fly_user_city"])}` || "Add",
      clickAction: () => {
        setIsSlidUpWriteVisible(true);
        setOpenWriteInput("current Location");
      },
    },
  ];

  const renderInput = () => {
    switch (openWriteInput) {
      case "email":
        return (
          <InputText
            inputType="text"
            value={userEmail.toLowerCase()}
            onChangeInput={(e) => setUserEmail(e)}
            placeholder="name@domain.com"
          />
        );
      case "city":
        return (
          <InputText
            inputType="text"
            value={userCity}
            onChangeInput={(e) => setUserCity(e)}
            placeholder="City"
          />
        );
      case "country":
        return (
          <InputText
            inputType="text"
            value={userCountry}
            onChangeInput={(e) => setUserCountry(e)}
            placeholder="Country"
          />
        );
      case "current Location":
        return (
          <div className="profile-input-group">
            <InputText
              inputType="text"
              value={userCountry}
              onChangeInput={(e) => setUserCountry(e)}
              placeholder="Country"
            />
            <InputText
              inputType="text"
              value={userCountry}
              onChangeInput={(e) => setUserCountry(e)}
              placeholder="City"
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="user-settings-profile">
      <PageTitle
        title={"Profile"}
        subtitle={"Configuration"}
        onBackClick={() => {
          setPrincipalScreen("/user-settings");
          setSecondaryScreen("");
        }}
        hasCloseIcon={false}
        className="padding-hor-24"
      />

      <div className="profile-picture-container margin-hor-24">
        <div className="profile-picture-container__photo">
          <img
            src={userProfilePicture ? userProfilePicture : avatarIconGray}
            alt="user profile"
          />
        </div>
        {/* <Button buttonStyle="tertiary" onClick={profilePictureChangeHandler}>
          Profile Picture
        </Button> */}
      </div>

      <div className="list-container-personal-info margin-24">
        {personalInfoData.map((item, index) => (
          <ListItemLink
            key={index}
            hasSeparator={personalInfoData.length > index + 1}
            hasArrowRight
            highlightText={item.labelContent}
            onClick={item.clickAction}
            rightNodeContent={
              <p className="text-detail">{item.valueContent}</p>
            }
          />
        ))}
      </div>

      <SlidUp
        classNameContainer="user-settings-slid-up"
        classNameChildren="list-item-slid-up "
        isOpen={isSlidUpVisible}
        title={titleSlidUp}
        closeArea={() => {
          setIsSlidUpVisible(false);
        }}
      >
        {modalSlidUpContent}
      </SlidUp>

      <SlidUp
        classNameContainer="user-settings-slid-up"
        classNameChildren="list-item-slid-up"
        isOpen={isSlidUpWriteVisible}
        title={openWriteInput}
        closeArea={() => {
          setIsSlidUpWriteVisible(false);
        }}
      >
        {renderInput()}

        <Button onClick={() => addSpecification()} buttonStyle="primary">
          Apply
        </Button>
        <Button
          buttonStyle="secondary"
          onClick={() => setIsSlidUpWriteVisible(false)}
        >
          Cancel
        </Button>
      </SlidUp>
    </div>
  );
};

export default UserSettingsProfilePage;
