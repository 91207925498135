export const clearLocalStorage = () => {
  localStorage.removeItem("@air-fly_user_lat");
  localStorage.removeItem("@air-fly_user_lon");
  localStorage.removeItem("@air-fly_access-token");
  localStorage.removeItem("@air-fly_display_name");
  localStorage.removeItem("@air-fly_user_avatar");
  localStorage.removeItem("@air-fly_user_email");
  localStorage.removeItem("@air-fly_user_id");
  localStorage.removeItem("@air-fly_newsletter");
  localStorage.removeItem("@air-fly_user_role");
  localStorage.removeItem("@air-fly_user_country");
  localStorage.removeItem("@air-fly_user");
  localStorage.removeItem("@air-fly_user_city");
};
