import fetchApi from "../fetchApi";

const getFlightDetailsById = async (token: string, flightId: string) => {
  return await fetchApi({
    service: "flights",
    endPoint: `/trip/${flightId}`,
    method: "GET",
    token,
  });
};

export default getFlightDetailsById;
