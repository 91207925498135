import fetchApi from "../fetchApi";

const getFlightLink = async (token: string, share?: string | null) => {
  return await fetchApi({
    service: "flights",
    endPoint: `/token/${token}${share ? "?share=" + share : ""}`,
    method: "GET",
  });
};

export default getFlightLink;
