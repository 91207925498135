import fetchApi from "../fetchApi";

const postResetPassword = async (data: { email: string }) => {
  return await fetchApi({
    service: "auth",
    endPoint: "/request-password-reset",
    method: "POST",
    data,
  });
};

export default postResetPassword;
