import React from "react";
import "./styles.scss";
import ListItem from "components/Lists/ListItem";
import checkSimpleGray from "../../../assets/icons/checks/check-simple-gray.svg";
import { textCapitalize } from "utils/textCapitalize";

interface RadioListOptions {
  title: string;
  price: number | string;
  description: string;
  value: string;
  icon: string;
  groupName: string;
  checked?: boolean;
  onChange: (value: string) => void;
  benefits?: string[];
}

const InputRadioSub: React.FC<RadioListOptions> = (props) => {
  const {
    title,
    price,
    description,
    value,
    icon,
    groupName,
    checked,
    onChange,
    benefits,
  } = props;

  return (
    <label id="option" className={`${checked ? "option__checked" : ""}`}>
      <div className="option-title">
        <h1 className="title">
          <b>{title.split(" ")[0]}</b> {textCapitalize(title.split(" ")[1])}
        </h1>
        <img src={icon} alt="" />
        <div className="input-select">
          {value === "bloom" && <div className="popular">Popular</div>}

          <input
            type="radio"
            value={value}
            name={groupName}
            checked={checked}
            onChange={() => onChange(value)}
            className="input-radio"
          />
        </div>
      </div>

      <div className="option-price">
        <span className="price">{price}</span>
        {price !== "Free" ? "/mo" : ""}
      </div>

      <div className="option-text">{description}</div>
      {checked &&
        benefits?.map((option, index) => (
          <div className="option-benefits" key={index}>
            <label className="benefits">{option}</label>
            <span className="icon icon-check icon-sm icon-orange"></span>
          </div>
        ))}
    </label>
  );
};

export default InputRadioSub;
