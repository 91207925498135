import { useState } from "react";
import "./styles.scss";

import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import PageTitle from "../../components/PageTitles";
import Toast from "../../components/Toast";

import ChangePassword from "../../api/user/postUserChangePassword";

import { useDeskNavigation } from "contexts/useDeskNavigation";
import { useUserInfo } from "contexts/userInfo";

import { IToast } from "../../types/toast";

import LockIcon from "../../assets/icons/lock/lock-icon-gray.svg";

const UserSettingsChangePasswordPage: React.FC = () => {
  const [userCurrentPassword, setUserCurrentPassword] = useState("");
  const [userNewPassword, setUserNewPassword] = useState("");
  const [userNewPasswordConfirm, setUserNewPasswordConfirm] = useState("");
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [toastShow, setToastShow] = useState(false);

  const [newPasswordWarning, setNewPasswordWarning] = useState(false);
  const [confirmNewPasswordWarning, setConfirmNewPasswordWarning] =
    useState(false);
  const [currentPasswordWarning, setCurrentPasswordWarning] = useState(false);

  const { setPrincipalScreen } = useDeskNavigation();
  const { userInfo } = useUserInfo();
  const resetPasswordSubmit = async () => {
    if (!userCurrentPassword) {
      handleToast({
        type: "error",
        title: "Reset password",
        description: "Check current password, please try again",
        beforeTime: () => setCurrentPasswordWarning(true),
        afterTime: () => setCurrentPasswordWarning(false),
      });
    }
    if (!userNewPassword) {
      handleToast({
        type: "error",
        title: "Reset password",
        description: "Check your credentials and, please, try again",
        beforeTime: () => setNewPasswordWarning(true),
        afterTime: () => setNewPasswordWarning(false),
      });
    }
    if (!userNewPasswordConfirm) {
      handleToast({
        type: "error",
        title: "Reset password",
        description: "Check your credentials and, please, try again",
        beforeTime: () => setConfirmNewPasswordWarning(true),
        afterTime: () => setConfirmNewPasswordWarning(false),
      });
    }
    if (
      userNewPassword === userNewPasswordConfirm &&
      userNewPassword.length >= 8
    ) {
      if (
        userNewPassword !== "" &&
        userNewPasswordConfirm !== "" &&
        userCurrentPassword
      ) {
        const update = await ChangePassword(userInfo["@air-fly_access-token"], {
          oldPassword: userCurrentPassword,
          newPassword: userNewPassword,
        });

        if (update.status === 200) {
          setUserCurrentPassword(userNewPassword);
          handleToast({
            type: "success",
            title: "Reset password",
            description: "Success changing passwords",
          });
        } else if (update.status === 404) {
          handleToast({
            type: "info",
            title: "Reset password",
            description:
              "Server error changing password, please try again later",
          });
        }
      }
    } else {
      handleToast({
        type: "error",
        title: "Reset password",
        description:
          "Your password should be at least 8 characters long and equal to.",
        beforeTime: () => {
          setNewPasswordWarning(true);
          setConfirmNewPasswordWarning(true);
        },
        afterTime: () => {
          setNewPasswordWarning(false);
          setConfirmNewPasswordWarning(false);
        },
      });
    }
  };

  const cancelSubmit = () => {
    setPrincipalScreen("/user-settings-account");
  };

  const handleToast = ({
    type,
    title,
    description,
    beforeTime,
    afterTime,
  }: IToast) => {
    setToastShow(true);
    setToastConfig({
      type,
      title,
      description,
    });

    beforeTime && beforeTime();

    setTimeout(() => {
      setToastShow(false);
      afterTime && afterTime();
    }, 5000);
  };

  return (
    <div className="user-settings-change-password">
      <PageTitle
        title={"Password & security"}
        subtitle={"Account Security"}
        hasCloseIcon={false}
        onBackClick={() => {
          setPrincipalScreen("/user-settings-account");
        }}
        className="padding-hor-24"
      />
      <div className="change-password-container margin-hor-24">
        <SubText className="margin-vert-16">
          Your password should be at least 8 characters long
        </SubText>
        <InputText
          value={userCurrentPassword}
          placeholder="Current Password"
          onChangeInput={(e) => setUserCurrentPassword(e)}
          inputType="password"
          isWarning={currentPasswordWarning}
        />
        <div className="separator" />
        <div className="password-container">
          <InputText
            value={userNewPassword}
            placeholder="New Password"
            onChangeInput={(e) => setUserNewPassword(e)}
            inputType="password"
            isWarning={newPasswordWarning}
          />
          <InputText
            value={userNewPasswordConfirm}
            placeholder="Confirm New Password"
            onChangeInput={(e) => setUserNewPasswordConfirm(e)}
            inputType="password"
            isWarning={confirmNewPasswordWarning}
          />
        </div>
        <SubText
          icon={LockIcon}
          altIcon="clock icon"
          className="margin-vert-16"
        >
          Never share your credentials with anyone.
        </SubText>{" "}
        <div className="buttons-container margin-bottom-24">
          <Button buttonStyle="primary" onClick={resetPasswordSubmit}>
            Reset Password
          </Button>
          <Button buttonStyle="quaternary" onClick={cancelSubmit}>
            Cancel
          </Button>
        </div>
      </div>

      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};
export default UserSettingsChangePasswordPage;
