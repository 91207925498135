import React, { ReactNode } from "react";
import "./styles.scss";

interface SubTextProps {
  icon?: string;
  altIcon?: string;
  children: ReactNode;
  checkAnswer?: boolean;
  checkValueCallback?: (a: boolean) => void;
  isChecked?: boolean;
  className?: string;
  fontSize?: string;
  fontFamily?: "Roboto Regular" | "Montserrat Variable";
  lineHeight?: string;
  leftText?: string;
  textAlign?: "left" | "right" | "center";
  isCheckBoxWarning?: boolean;
}

const SubText: React.FC<SubTextProps> = (props) => {
  const {
    icon,
    altIcon,
    children,
    checkAnswer,
    checkValueCallback,
    isChecked,
    className,
    fontSize = "12px",
    fontFamily = "Roboto Regular",
    lineHeight = "1.4",
    textAlign,
    leftText,
    isCheckBoxWarning,
  } = props;

  return (
    <div id="subtext-component" className={className ?? ""}>
      {icon ? (
        <img src={icon} alt={altIcon} className="subtext-icon" />
      ) : leftText ? (
        <p className="subtext-left-text">{leftText}</p>
      ) : (
        false
      )}
      {checkAnswer && checkValueCallback && (
        <label
          className={`container-checkbox ${isCheckBoxWarning ? "warning" : ""}`}
        >
          <input
            className="subtext-input-checkbox"
            type="checkbox"
            checked={isChecked}
            onChange={(e) => checkValueCallback(e.target.checked)}
          />
          <span className="checkmark"></span>
        </label>
      )}
      <p
        className={`text-gray margin-vert-16 ${
          checkAnswer ? "checkbox-margin" : ""
        }`}
        style={{ fontSize, fontFamily, lineHeight, textAlign }}
      >
        {children}
      </p>
    </div>
  );
};

export default SubText;
