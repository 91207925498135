import fetchApi from "../fetchApi";

export type PostVerifyEmailBody = {
  token: string;
};

const postVerifyEmail = async (data: PostVerifyEmailBody) => {
  return await fetchApi({
    service: "waitlists",
    endPoint: `/verify-email`,
    method: "POST",
    data,
  });
};

export default postVerifyEmail;
