import React, { useEffect, useState } from "react";
import "./styles.scss";
import googleIcon from "../../assets/icons/socialMedia/google-logo-gray.svg";
import { useNavigate } from "react-router-dom";
import { IToast } from "types/toast";
import Header from "components/Headers/Header";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";
import Toast from "components/Toast";
import getAuthGoogle from "api/auth/getAuthGoogle";
import { useUserInfo } from "contexts/userInfo";
import userAuthLogin from "api/auth/postLogin";
import getUserProfile from "api/user/getUserProfile";
import { useLoginSignUpNavigation } from "contexts/useLoginSignUpNavigation";
import Loading from "components/Loading";
import { handleGetLocation } from "utils/getUserGeoLocation";
import patchUserCoordinates from "api/user/patchUserCoordinates";
import { clearLocalStorage } from "utils/clearLocalStorage";
import CheckCircleGreen from "../../assets/icons/checks/check-circle-green.svg";
import { useModal } from "contexts/useModal";
import ErrorCheck from "../../assets/icons/close/close-icon-pink.svg";
import InfoIcon from "../../assets/icons/info/info-gray.svg";
import postWaitList, { PostWaitListBody } from "api/waitList/postWaitList";
import postSendLinkEmailToVerify from "api/emailVerification/postSendLinkEmailToVerify";
import postVerifyEmailToken from "api/emailVerification/postVerifyEmailToken";
import InputRadioSub from "components/Inputs/InputRadioSub";
import getCurrentPlan from "api/plans/getCurrentPlan";
import { IUserPlan } from "types/userPlan";
import finger from "../../assets/icons/fingerPrint/finger-print.svg";
import xicon from "../../assets/icons/socialMedia/x-logo-gray.svg";

const AccountLoginPage: React.FC = () => {
  //STATES
  const [emailInputWarning, setEmailInputWarning] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordInputWarning, setPasswordInputWarning] = useState(false);
  const { userInfo, setUserInfo } = useUserInfo();
  const { setComponent } = useLoginSignUpNavigation();
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  console.debug("juh");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const code = params.get("code");
    const error = params.get("error");
    const via = params.get("via");

    if (via === "oauth") {
      if (error) {
        if (code === "409") {
          const newUrl = window.location.origin + window.location.pathname;
          window.history.replaceState(null, "", newUrl);
          setToastShow(true);

          setToastConfig({
            description: "This account is not linked to Google",
            title: "Oops...",
            type: "info",
          });

          setTimeout(() => {
            setToastShow(false);
          }, 5000);
        }
      } else if (token) {
        tokenTreatment(200, token);
      }
    } else if (via === "email") {
      if (token) verifyEmailToken(token);
    }
  }, [window.location.search]);

  const verifyEmailToken = async (token: string) => {
    try {
      const request = await postVerifyEmailToken(
        userInfo["@air-fly_access-token"],
        token
      );

      switch (request.status) {
        case 200:
          setComponent(
            <div className="main__verify-email-message">
              <img
                src={CheckCircleGreen}
                alt="check"
                className="status-request"
              />

              <h2 className="main__verify-email-message--title">
                Email verified successfully!
              </h2>
              <p className="main__verify-email-message--explanation-text">
                Take the opportunity to upload it to your monthly plan and get
                more benefits and get ahead!
              </p>
            </div>
          );

          break;
        case 400:
          if (request.res.message.includes("400006")) {
            setComponent(
              <div className="main__verify-email-message">
                <h2 className="main__verify-email-message--title">
                  Your email has already been verified
                </h2>
                <p className="main__verify-email-message--explanation-text">
                  Please check the link sent to your email and try again
                </p>
              </div>
            );
          }

          break;
        default:
          setComponent(
            <div className="main__verify-email-message">
              <img
                src={ErrorCheck}
                alt="error check"
                className="status-request"
              />
              <h2 className="main__verify-email-message--title">
                Unable to verify your email.
              </h2>
              <p className="main__verify-email-message--explanation-text">
                Please check the link sent to your email and try again, or try
                to send email again.
              </p>
            </div>
          );
          break;
      }
    } catch (error) {
      setComponent(
        <div className="main__verify-email-message">
          <img src={ErrorCheck} alt="error check" className="status-request" />
          <h2 className="main__verify-email-message--title">
            Unable to verify your email.
          </h2>
          <p className="main__verify-email-message--explanation-text">
            Please check the link sent to your email and try again, or try to
            send email again.
          </p>
        </div>
      );
    } finally {
      const params = new URLSearchParams(window.location.search);
      params.delete("token");
      params.delete("via");
      const url = new URL(window.location.href);
      url.search = params.toString();
      window.history.replaceState(null, "", url);
      setTimeout(() => {
        setComponent("/login");
      }, 7000);
    }
  };

  const tokenTreatment = async (status: number | null, token: string) => {
    switch (status) {
      case 200:
        try {
          clearLocalStorage();
          const userProfile = await getUserProfile(token);
          const currentPlan = await getCurrentPlan(token);

          setUserInfo("@air-fly_access-token", token);

          switch (userProfile.status) {
            case 200:
              if (
                !userInfo["@air-fly_geo_location_permission"] ||
                !JSON.parse(userInfo["@air-fly_geo_location_permission"])
              ) {
                handleGetLocation()
                  .then(async (res) => {
                    setUserInfo(
                      "@air-fly_user_lat",
                      JSON.stringify(res.latitude)
                    );
                    setUserInfo(
                      "@air-fly_user_lon",
                      JSON.stringify(res.longitude)
                    );

                    await patchUserCoordinates(
                      token,
                      res.longitude,
                      res.latitude
                    );
                  })
                  .catch((res) =>
                    setUserInfo("@air-fly_geo_location_permission", "false")
                  )
                  .finally(() => {
                    setUserInfo("@air-fly_access-token", token);
                    setUserInfo(
                      "@air-fly_display_name",
                      userProfile.res.displayName
                    );
                    setUserInfo(
                      "@air-fly_user_avatar",
                      userProfile.res.avatarUrl
                    );
                    setUserInfo("@air-fly_user_email", userProfile.res.email);
                    setUserInfo("@air-fly_user_id", userProfile.res.id);
                    setUserInfo(
                      "@air-fly_newsletter",
                      userProfile.res.newsLetter
                    );
                    setUserInfo("@air-fly_user_role", userProfile.res.role);
                    setUserInfo(
                      "@air-fly_user_country",
                      userProfile.res.country
                    );
                    setUserInfo("@air-fly_user_city", userProfile.res.city);
                    setUserInfo("@air-fly_user_phone", userProfile.res.phone);
                    setUserInfo(
                      "@air-fly_phone-verified",
                      userProfile.res.isPhoneVerified
                    );
                    if (currentPlan.res.plan) {
                      setUserInfo(
                        "@air-fly_user-plan",
                        currentPlan.res.plan.name
                      );
                    } else {
                      setUserInfo("@air-fly_user-plan", "free");
                    }

                    navigate("/home");
                  });
              }
              break;
            case 403:
              setComponent(pageEmailVerifyContent("send-email"));

              setIsLoading(false);

              break;
            default:
              setIsLoading(false);
              setToastConfig({
                description:
                  "We had a problem making the request. Please try again",
                title: "Sorry",
                type: "error",
              });

              setToastShow(true);
              setTimeout(() => {
                setToastShow(false);
              }, 4000);
              break;
          }
        } catch (error) {
          console.error(error);
          setIsLoading(false);
          setToastConfig({
            description:
              "We had a problem making the request. Please try again",
            title: "Sorry",
            type: "error",
          });

          setToastShow(true);
          setTimeout(() => {
            setToastShow(false);
          }, 4000);
        }

        break;
      case 401:
        setIsLoading(false);
        setToastConfig({
          description: "Check your credentials and, please, try again",
          title: "Error",
          type: "error",
        });
        setToastShow(true);
        setEmailInputWarning(true);
        setPasswordInputWarning(true);

        setTimeout(() => {
          setEmailInputWarning(false);
          setPasswordInputWarning(false);
          setToastShow(false);
        }, 4000);

        break;

      default:
        setToastConfig({
          description: "We had a problem making the request. Please try again",
          title: "Sorry",
          type: "error",
        });

        setToastShow(true);
        setTimeout(() => {
          setToastShow(false);
        }, 4000);
        break;
    }
  };

  const loginSubmit = async (method: "interAuth" | "oauthGoogle") => {
    if (method === "oauthGoogle") {
      const responseAuth = getAuthGoogle();
    } else if (method === "interAuth") {
      if (userEmail.length < 5) {
        setToastConfig({
          description: "You need to add your email to continue",
          title: "Email is empty",
          type: "info",
        });
        setToastShow(true);
        setEmailInputWarning(true);

        setTimeout(() => {
          setEmailInputWarning(false);
          setToastShow(false);
        }, 4000);

        return;
      }
      if (!userEmail.includes("@") || !userEmail.includes(".com")) {
        setToastConfig({
          description: "Add a valid email to continue.",
          title: "Invalid Email",
          type: "info",
        });
        setToastShow(true);
        setEmailInputWarning(true);

        setTimeout(() => {
          setEmailInputWarning(false);
          setToastShow(false);
        }, 4000);

        return;
      }

      if (!userPassword) {
        setToastConfig({
          description: "You need to add your password to continue",
          title: "Password is empty",
          type: "info",
        });

        setToastShow(true);
        setPasswordInputWarning(true);
        setTimeout(() => {
          setPasswordInputWarning(false);
          setToastShow(false);
        }, 4000);

        return;
      }

      setIsLoading(true);

      const prepareData = {
        email: userEmail.trim(),
        password: userPassword,
      };
      try {
        const responseAuth = await userAuthLogin(prepareData);

        switch (responseAuth.status) {
          case 200:
            const access_token = responseAuth.res.access_token;
            setToken(responseAuth.res.access_token);
            tokenTreatment(responseAuth?.status, access_token);
            break;

          case 401:
            setIsLoading(false);
            setToastConfig({
              description: "Check your credentials and, please, try again",
              title: "Error",
              type: "error",
            });
            setToastShow(true);
            setEmailInputWarning(true);
            setPasswordInputWarning(true);

            setTimeout(() => {
              setEmailInputWarning(false);
              setPasswordInputWarning(false);
              setToastShow(false);
            }, 4000);
            break;
          default:
            setToastConfig({
              description:
                "We had a problem making the request. Please try again",
              title: "Sorry",
              type: "error",
            });

            setToastShow(true);
            setTimeout(() => {
              setToastShow(false);
            }, 4000);
            break;
        }
      } catch (error) {
        console.error(error);
        setToastConfig({
          description: "We had a problem making the request. Please try again",
          title: "Sorry",
          type: "error",
        });

        setToastShow(true);
        setTimeout(() => {
          setToastShow(false);
        }, 4000);
      }
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      loginSubmit("interAuth");
    }
  };

  const closePageVerifyEmail = () => {
    setUserEmail("");
    setUserPassword("");
    setComponent(loginComponent);
  };

  const pageEmailVerifyContent = (status: "success" | "send-email") => {
    if (status === "success") {
      return (
        <section className="email-verify-content-modal">
          <img src={CheckCircleGreen} alt="check" className="status-request" />

          <h5 className="no-margin email-verify-content-modal__title">
            Verification email sent successfully
          </h5>
          <p className="small-p email-verify-content-modal__explanation-text">
            Check your email inbox and click on the link we sent you. Also check
            your spam folder
          </p>
        </section>
      );
    } else if (status === "send-email") {
      return (
        <section className="email-verify-content-modal">
          <img src={InfoIcon} alt="check" className="status-request" />

          <h5 className="no-margin email-verify-content-modal__title">
            Your email has not yet been verified{" "}
          </h5>
          <p className="small-p email-verify-content-modal__explanation-text">
            AirFly needs to verify your email so you can have a better
            experience on our app
          </p>
          <div className="buttons-container">
            <Button onClick={sendEmailVerificationLink} buttonStyle="primary">
              Send Again
            </Button>
            <Button buttonStyle="tertiary" onClick={closePageVerifyEmail}>
              Cancel
            </Button>
          </div>
        </section>
      );
    }
  };

  const sendEmailVerificationLink = async () => {
    setComponent(<Loading />);
    try {
      const request = await postSendLinkEmailToVerify(
        userInfo["@air-fly_access-token"]
      );

      switch (request.status) {
        case 200:
          setComponent(pageEmailVerifyContent("success"));

          setTimeout(() => {
            setComponent(loginComponent);
          }, 6000);
          break;

        default:
          setToastConfig({
            description:
              "We had a problem making the request. Please try again",
            title: "Sorry",
            type: "error",
          });
          setToastShow(true);

          setTimeout(() => {
            setToastShow(false);
          }, 4000);
          break;
      }
    } catch (error) {
      console.error(error);

      setToastConfig({
        description: "We had a problem making the request. Please try again",
        title: "Sorry",
        type: "error",
      });
      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
      }, 4000);
    }
  };

  const loginComponent = (
    <div className="account-login-page padding-bottom-24">
      <Header
        headerIcon="airFly"
        title="Welcome!"
        titleClass="header"
        subTitle={`For your security, please log in to continue accessing our
				platform.`}
      />
      <div className="login-container margin-top-24">
        <InputText
          value={userEmail.toLowerCase()}
          inputType="email"
          placeholder="Email"
          // textTransform="none"
          onChangeInput={(e) => setUserEmail(e)}
          isWarning={emailInputWarning}
          className="no-margin"
        />
        <InputText
          inputType="password"
          value={userPassword}
          placeholder="Password"
          onChangeInput={(e) => setUserPassword(e)}
          onKeyDown={onKeyDown}
          isWarning={passwordInputWarning}
          className="no-margin"
        />
        <Button
          disabled={isLoading}
          buttonStyle="tertiary"
          onClick={() => loginSubmit("interAuth")}
        >
          Login
        </Button>

        <Button
          buttonStyle="quaternary"
          onClick={() => loginSubmit("oauthGoogle")}
        >
          <img src={googleIcon} alt="google icon" />
        </Button>

        {/*          <div className="group-buttons">
            <Button
              buttonStyle="quaternary"
              onClick={() => loginSubmit("oauthGoogle")}
            >
              <img src={xicon} alt="google icon" />
            </Button>{" "}
            <Button
              buttonStyle="quaternary"
              onClick={() => loginSubmit("oauthGoogle")}
            >
              <img src={googleIcon} alt="google icon" />
            </Button>{" "}
            <Button
              buttonStyle="quaternary"
              onClick={() => loginSubmit("oauthGoogle")}
            >
              <img src={finger} alt="google icon" />
            </Button>
          </div> */}
      </div>

      <div className="buttons-container margin-top-24">
        <Button
          disabled={isLoading}
          buttonStyle="quintenary"
          onClick={() => setComponent("/reset-password-email-sender")}
        >
          Forgot Password
        </Button>
        <Button
          disabled={isLoading}
          buttonStyle="primary"
          onClick={() => setComponent("/create-account")}
        >
          Create account
        </Button>
        <SubText>
          By tapping any of the actions above, you acknowledge that you are over
          the age of 18 and agree to our Terms of Services. Learn how we process
          your data in our Privacy Policy.
        </SubText>
      </div>
      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );

  if (isLoading) {
    return <Loading />;
  }

  return loginComponent;
};

export default AccountLoginPage;
