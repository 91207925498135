import fetchApi from "../fetchApi";

const getMileageProgram = async (token: string) => {
  return await fetchApi({
    service: "mileage",
    endPoint: ``,
    method: "GET",
    token,
  });
};

export default getMileageProgram;
