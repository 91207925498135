import fetchApi from "../fetchApi";

const getNearbyAirports = async (token: string) => {
  return await fetchApi({
    service: "airports",
    endPoint: "/nearby",
    method: "GET",
    token,
  });
};

export default getNearbyAirports;
