import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import Button from "../../components/Buttons/Button";
import ListItemLink from "components/Lists/ListItemLink";

import { useDeskNavigation } from "contexts/useDeskNavigation";

import identityIconGray from "../../assets/icons/avatars/identity-icon-gray.svg";
import languageIcon from "../../assets/icons/language/language-icon-gray.svg";
import lockIcon from "../../assets/icons/lock/lock-icon-gray.svg";
import personalSafetyIconGray from "../../assets/icons/shieldIcons/personal-icon-gray.svg";
import gearIcon from "../../assets/icons/gear/gear-icon-gray.svg";
import { clearLocalStorage } from "utils/clearLocalStorage";

const UserSettingsAccountPage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();

  const navigate = useNavigate();

  const logoutHandler = () => {
    clearLocalStorage();
    navigate("/");
  };

  const listItems = [
    // {
    //   labelContent: "Identity Verification",
    //   iconLeftContent: identityIconGray,
    //   clickAction: () => {},
    // },
    // {
    //   labelContent: "Language & Location",
    //   iconLeftContent: languageIcon,
    //   clickAction: () => setSecondaryScreen("/user-settings-language"),
    // },
    {
      labelContent: "Password & Security",
      iconLeftContent: personalSafetyIconGray,
      clickAction: () => setPrincipalScreen("/user-settings-change-password"),
    },
    // {
    //   labelContent: "Account Privacy",
    //   iconLeftContent: lockIcon,
    //   clickAction: () => setPrincipalScreen("/user-settings-privacy"),
    // },
    {
      labelContent: "Manage Account",
      iconLeftContent: gearIcon,
      clickAction: () => setPrincipalScreen("/user-settings-manage-account"),
    },
  ];

  return (
    <div className="user-settings-account">
      <PageTitle
        title={"Account"}
        subtitle={"Manage Account"}
        onBackClick={() => {
          setPrincipalScreen("/user-settings");
        }}
        hasCloseIcon={false}
        className="padding-hor-24"
      />
      <div className="list-container-user-settings margin-hor-24">
        {listItems.map((item, index, arr) => (
          <ListItemLink
            key={index}
            onClick={item.clickAction}
            hasArrowRight
            highlightText={item.labelContent}
            isImageCircle
            imageLeft={item.iconLeftContent}
            hasSeparator={arr.length > index + 1}
          />
        ))}

        <div className="button-bottom">
          <Button
            buttonStyle="secondary"
            onClick={logoutHandler}
            className="margin-top-24"
          >
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserSettingsAccountPage;
