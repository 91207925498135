import React, { ReactNode } from "react";
import "./styles.scss";
import TitleText from "../../Texts/TitleText";
import leftArrowGray from "../../../assets/icons/arrows/chevron-left-gray.svg";
import AirFlyLogo from "../../../assets/logos/air-fly-logo-1.svg";

interface HeaderProps {
  title?: ReactNode;
  tagTitle?: "title" | "h1" | "h2" | "h3" | "h4";
  subTitle?: ReactNode;
  headerIcon?: "back-button" | "airFly" | undefined;
  backButtonClick?: React.MouseEventHandler<HTMLImageElement> | undefined;
  marginTop?: number;
  hasGapBetweenTitleAndSub?: boolean;
  titleClass?: "header";
  className?: string;
  text?: string;
}

const Header: React.FC<HeaderProps> = (props) => {
  const {
    title,
    subTitle,
    headerIcon,
    backButtonClick,
    marginTop,
    className,
    tagTitle,
    titleClass,
    text,
  } = props;

  const switchIcon = () => {
    switch (headerIcon) {
      case "back-button":
        return leftArrowGray;
      case "airFly":
        return AirFlyLogo;
      default:
        return undefined;
    }
  };

  const icon = switchIcon();

  return (
    <header
      id="header-default"
      className={className ?? ""}
      style={{ marginTop: marginTop }}
    >
      {headerIcon && (
        <div
          className={`icon-container ${
            (headerIcon === "airFly" && "arFly-logo-text") ?? ""
          }`}
        >
          <img
            src={icon}
            alt={`${
              headerIcon === "back-button" ? "left arrow icon" : "airFly logo"
            }`}
            onClick={headerIcon === "back-button" ? backButtonClick : undefined}
          />
        </div>
      )}

      <TitleText size={tagTitle} titleClass={titleClass}>
        {title}
      </TitleText>
      {text && <p className="subtitle">{text}</p>}

      {subTitle && <p className="description">{subTitle}</p>}
    </header>
  );
};

export default Header;
