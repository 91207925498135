import fetchApi from "../fetchApi";

const postGameAnswer = async (token: string, data: { answer: string }) => {
  return await fetchApi({
    service: "game",
    endPoint: `/answer`,
    method: "POST",
    token,
    data,
  });
}

export default postGameAnswer;
