import React, { useEffect, useState } from "react";
import "./styles.scss";
import Button from "components/Buttons/Button";
import SlidUp from "../SlidUp";
import InputTextSelect, {
  IOptionInputTextSelectProps,
} from "components/Inputs/InputTextSelect";
import InputText from "components/Inputs/InputText";
import DropDown from "components/Lists/DropDown";
import ChevronDown from "../../../assets/icons/arrows/bx-chevron-down.svg";
import linkDestination from "../../../assets/icons/arrows/link-destination.svg";
import buttonChange from "../../../assets/icons/buttons/button-change.svg";
import { IAirportsSearch } from "types/airportsSearch";
import closeIcon from "../../../assets/icons/close/close-icon-gray.svg";
import ArrowDown from "../../../assets/icons/arrows/bx-chevron-down-orange.svg";
import { useModal } from "contexts/useModal";
import getAirportsCodes from "api/flights/getAirportsCodes";
import { useUserInfo } from "contexts/userInfo";
import getNearbyAirports from "api/airports/getNerbyAirports";

interface ModalFilterFlightsProps {
  className?: string;
  inputSearchResultOptions: IOptionInputTextSelectProps[];
  filterResultsCallback?: (obj: FilterObjectSlidUp) => void;
  titleHead?: string;
  titleText?: string;
  primaryButtonText?: string;
  showDate?: boolean;
}

export type FilterObjectSlidUp = {
  departureIATA: string;
  departureCoord: Coordinates;
  destinationIATA: string;
  destinationCoord: Coordinates;
  dateSelected: string;
  stopsSelected: string;
  classSelected: string;
  paymentMethod: string;
};

type Coordinates = {
  lat: number;
  lon: number;
};

const ModalFilterFlights: React.FC<ModalFilterFlightsProps> = (props) => {
  const {
    titleHead,
    titleText,
    primaryButtonText,
    className,
    filterResultsCallback,
    showDate,
  } = props;
  const { userInfo } = useUserInfo();
  const { openUseModal, setModalContent, setOpenUseModal } = useModal();
  const [departure, setDeparture] = useState("");
  const [destination, setDestination] = useState("");
  const [departureInputWarning, setDepartureInputWarning] = useState(false);
  const [destinationInputWarning, setDestinationInputWarning] = useState(false);
  const [dateSelectWarning, setDateSelectWarning] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("miles");
  const [dateSelected, setDateSelected] = useState("");
  const [stopsSelected, setStopsSelected] = useState<string[]>([]);
  const [classSelected, setClassSelected] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [inputDestination, setInputDestination] = useState("");
  const [inputDeparture, setInputDeparture] = useState("");
  const [inputSearchResultOptions, setInputSearchResultOptions] = useState(
    {} as IOptionInputTextSelectProps[]
  );
  const [isModifiedDestinyAndDeparture, setIsModifiedDestinyAndDeparture] =
    useState(false);

  useEffect(() => {
    const nearbyAirports = async () => {
      try {
        const request = await getNearbyAirports(
          userInfo["@air-fly_access-token"]
        );
        if (request.status === 200) {
        }

        setDeparture(JSON.stringify(request?.res[0]));
        setInputDeparture(
          `${request?.res[0]?.iata} - ${request?.res[0]?.name}`
        );
      } catch (error) {
        console.error(error);
      }
    };

    nearbyAirports();
  }, []);

  const clearFields = () => {
    setDateSelected("");
    setClassSelected([]);
    setDeparture("");
    setDestination("");
    setPaymentMethod("miles");
    setStopsSelected([]);
    setInputDestination("");
    setInputDeparture("");
  };

  const filterFlightsHandler = () => {
    if (isLoading) return;
    setIsLoading(true);
    if (!departure || !destination) {
      if (!departure) setDepartureInputWarning(true);
      if (!destination) setDestinationInputWarning(true);

      setTimeout(() => {
        setDepartureInputWarning(false);
        setDestinationInputWarning(false);
      }, 3000);
      setIsLoading(false);
      return;
    }

    if (showDate && !dateSelected) {
      setDateSelectWarning(true);
      setTimeout(() => {
        setDateSelectWarning(false);
      }, 3000);
      setIsLoading(false);
      return;
    }

    const departureParse: IAirportsSearch = JSON.parse(departure);
    const destinationParse: IAirportsSearch = JSON.parse(destination);

    if (
      !inputDestination
        .trim()
        .toLowerCase()
        .startsWith(destinationParse.iata.toLowerCase())
    ) {
      setDepartureInputWarning(true);
      setDestinationInputWarning(true);
      setTimeout(() => {
        setDestinationInputWarning(false);
        setDepartureInputWarning(false);
      }, 3000);
      setIsLoading(false);
      return;
    }
    if (
      !inputDeparture
        .trim()
        .toLowerCase()
        .startsWith(departureParse.iata.toLowerCase())
    ) {
      setDepartureInputWarning(true);
      setDestinationInputWarning(true);
      setTimeout(() => {
        setDestinationInputWarning(false);
        setDepartureInputWarning(false);
      }, 3000);
      setIsLoading(false);
      return;
    }

    const departureCoord = {
      lat: departureParse.latitude,
      lon: departureParse.longitude,
    };

    const destinationCoord = {
      lat: destinationParse.latitude,
      lon: destinationParse.longitude,
    };

    const prepareDataObj: FilterObjectSlidUp = {
      destinationIATA: !isModifiedDestinyAndDeparture
        ? destinationParse.iata
        : departureParse.iata,
      departureIATA: isModifiedDestinyAndDeparture
        ? destinationParse.iata
        : departureParse.iata,

      departureCoord: !isModifiedDestinyAndDeparture
        ? departureCoord
        : destinationCoord,

      destinationCoord: isModifiedDestinyAndDeparture
        ? departureCoord
        : destinationCoord,

      classSelected: classSelected[0] ?? undefined,
      dateSelected,
      paymentMethod,
      stopsSelected: stopsSelected[0] ?? undefined,
    };

    filterResultsCallback?.(prepareDataObj);

    closeModal();
    setIsLoading(false);
  };

  const stopsOptions = [
    {
      groupName: "stops",
      inputLabel: `Direct`,
      inputValue: `Direct`,
    },
    {
      groupName: "stops",
      inputLabel: `stopover`,
      inputValue: `stopover`,
    },
  ];

  const flightClassOption = [
    {
      groupName: "class",
      inputLabel: `economic`,
      inputValue: `economic`,
    },
    {
      groupName: "class",
      inputLabel: `premium`,
      inputValue: `premium`,
    },
    {
      groupName: "class",
      inputLabel: `executive`,
      inputValue: `executive`,
    },
    {
      groupName: "class",
      inputLabel: `first`,
      inputValue: `first`,
    },
  ];

  const closeModal = () => {
    setOpenUseModal(false);
    clearFields();
    setIsLoading(false);
  };

  const advancedOptionsHandler = () => {
    setShowAdvancedOptions((prev) => !prev);
  };

  const handleInputChange = async (search: string) => {
    try {
      const request = await getAirportsCodes(
        userInfo["@air-fly_access-token"],
        {
          search,
        }
      );

      setInputSearchResultOptions(
        request.res.data.map((airport: IAirportsSearch) => {
          return {
            label: `${airport.iata} - ${airport.name}`,
            detailsText: `${airport.city}, ${airport.country}`,
            value: JSON.stringify(airport),
          } as IOptionInputTextSelectProps;
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div id="modal-filter-flights" className={className ?? ""}>
      <img src={closeIcon} alt="close icon" onClick={closeModal} />
      <h2 className="modal-filter-flights__title margin-top-24">{titleHead}</h2>
      <hr className={`dotted-hr`} />

      <div className="grid-container">
        <p className="title">{titleText}</p>
        <div className="grid-container-destination">
          <InputTextSelect
            className="header-navigate-default__input-text-select margin-bottom-16"
            options={inputSearchResultOptions}
            onSelect={(e) => {
              if (!isModifiedDestinyAndDeparture) {
                setDeparture(e);
              } else {
                setDestination(e);
              }
            }}
            inputValue={
              !isModifiedDestinyAndDeparture ? inputDeparture : inputDestination
            }
            setInputValue={(e) => {
              if (!isModifiedDestinyAndDeparture) {
                setInputDeparture(e);
              } else {
                setInputDestination(e);
              }
            }}
            onChangeInput={handleInputChange}
            inputPlaceholder={"Select Departure"}
            isWarning={departureInputWarning}
          />
          <InputTextSelect
            className="header-navigate-default__input-text-select "
            options={inputSearchResultOptions}
            onSelect={(e) => {
              if (isModifiedDestinyAndDeparture) {
                setDeparture(e);
              } else {
                setDestination(e);
              }
            }}
            inputValue={
              isModifiedDestinyAndDeparture ? inputDeparture : inputDestination
            }
            setInputValue={(e) => {
              if (isModifiedDestinyAndDeparture) {
                setInputDeparture(e);
              } else {
                setInputDestination(e);
              }
            }}
            onChangeInput={handleInputChange}
            inputPlaceholder={"Select Destination"}
            isWarning={destinationInputWarning}
          />

          <div className="grid-img">
            <img
              src={linkDestination}
              alt=""
              className={`link-destination ${
                isModifiedDestinyAndDeparture ? "modified" : ""
              }`}
            />
            <img
              src={buttonChange}
              alt="button change"
              onClick={() => setIsModifiedDestinyAndDeparture((prev) => !prev)}
            />
          </div>
        </div>
      </div>
      {showDate && (
        <div>
          <p className="title">Date</p>
          <InputText
            inputType="date"
            value={dateSelected}
            onChangeInput={setDateSelected}
            placeholder="Select Date"
            // className="margin-bottom-16"
            isWarning={dateSelectWarning}
          />
        </div>
      )}
      <div
        className={`advanced-options ${
          showAdvancedOptions ? "padding-top-8" : "padding-vert-16"
        }`}
        onClick={advancedOptionsHandler}
      >
        <p className={`advanced-options__text`}>Advanced Options</p>
        <img
          src={ArrowDown}
          alt="arrow"
          className={`arrow ${showAdvancedOptions ? "arrow-down" : "arrow-up"}`}
        />
      </div>

      {showAdvancedOptions ? (
        <>
          {showDate ? (
            <></>
          ) : (
            <div>
              <p className="title">Date</p>
              <InputText
                inputType="date"
                value={dateSelected}
                onChangeInput={setDateSelected}
                placeholder="Select Date"
                // className="margin-bottom-16"
                isWarning={dateSelectWarning}
              />
            </div>
          )}

          <div className="grid-container">
            <p className="title">Flight Options</p>
            <div className="dropdowns margin-bottom-16">
              <DropDown
                defaultValueProp={[stopsOptions[0].inputValue]}
                setValueCallBack={setStopsSelected}
                placeholder={"Any"}
                options={stopsOptions}
                dropType="radio"
                readOnly={true}
              />
              <DropDown
                setValueCallBack={setClassSelected}
                placeholder={"Any"}
                options={flightClassOption}
                dropType="radio"
                readOnly={true}
              />
            </div>
          </div>
          {/* <div className="grid-container-radio">
            <div className="container">
              <input
                className="input-radio"
                type="radio"
                id={`miles-filter`}
                name={"payment-method"}
                value={"miles"}
                onChange={(e) => setPaymentMethod(e.target.value)}
                checked={paymentMethod === "miles"}
              />
              <label htmlFor="miles-filter">Miles</label>
            </div>

            <div className="container">
              <input
                className="input-radio"
                type="radio"
                id={`cash-filter`}
                name={"payment-method"}
                value={"cash"}
                onChange={(e) => setPaymentMethod(e.target.value)}
                checked={paymentMethod === "cash"}
              />
              <label htmlFor="cash-filter">Cash</label>
            </div>
          </div> */}
        </>
      ) : (
        false
      )}

      <Button onClick={filterFlightsHandler} buttonStyle="primary">
        {primaryButtonText}
      </Button>
      <Button buttonStyle="quintenary" onClick={clearFields}>
        Clear all
      </Button>
    </div>
  );
};

export default ModalFilterFlights;
