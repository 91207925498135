import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { getElementByPath } from "utils/navigationDesk";

interface Params {
  [key: string]: string;
}

interface LoginSignUpContextType {
  setComponent: (path: string | ReactNode, params?: Params) => void;
  component: ReactNode;
  params: Params;
}

const LoginSignUpContext = createContext({} as LoginSignUpContextType);

const LoginSignUpProvider = ({ children }: { children: ReactNode }) => {
  const [componentState, setComponentState] = useState<string | ReactNode>("");
  const [component, setComponent] = useState<ReactNode | null>(null);

  const [params, setParams] = useState<Params>({});

  const setScreen = (
    setState: React.Dispatch<React.SetStateAction<string | ReactNode>>,
    path: string | ReactNode,
    params?: Params
  ) => {
    if (params) {
      setParams(params);
    }
    setState(path);
  };

  useEffect(() => {
    let page;
    if (typeof componentState === "string") {
      page = getElementByPath(componentState);
    } else {
      page = componentState;
    }

    if (page) {
      setComponent(page);
    } else {
      setComponent(null);
    }
  }, [componentState, setComponentState]);

  return (
    <LoginSignUpContext.Provider
      value={{
        component,
        setComponent: (path, params) =>
          setScreen(setComponentState, path, params),
        params,
      }}
    >
      {children}
    </LoginSignUpContext.Provider>
  );
};

const useLoginSignUpNavigation = () => {
  const context = useContext(LoginSignUpContext);

  return context;
};

export { LoginSignUpProvider, useLoginSignUpNavigation };
