import fetchApi from "api/fetchApi";

export type GetListFavoriteFlightsQueryParams = {
  take?: number;
  skip?: number;
};

const getListFavoriteFlights = async (
  token: string,
  queryParams?: Partial<GetListFavoriteFlightsQueryParams>
) => {
  const params: Record<string, any> = {};

  if (queryParams?.take || queryParams?.take !== undefined) {
    params.take = queryParams?.take;
  }

  if (queryParams?.skip || queryParams?.skip !== undefined) {
    params.skip = queryParams?.skip;
  }
  return await fetchApi({
    service: "users",
    endPoint: `/favorites`,
    method: "GET",
    token,
    params,
  });
};

export default getListFavoriteFlights;
