import fetchApi from "../fetchApi";

const patchUserCoordinates = async (
  token: string,
  longitude: number,
  latitude: number
) => {
  return await fetchApi({
    service: "users",
    endPoint: `/update-coordinates`,
    method: "PATCH",
    token,
    data: {
      latitude,
      longitude,
    },
  });
};

export default patchUserCoordinates;
