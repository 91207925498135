import React, { useState } from "react";
import "./styles.scss";

import PageTitle from "../../components/PageTitles";
import InputText from "../../components/Inputs/InputText";
import SubText from "../../components/Texts/SubText";
import Button from "../../components/Buttons/Button";
import Toast from "../../components/Toast";

import { IToast } from "../../types/toast";

import { useDeskNavigation } from "contexts/useDeskNavigation";
import { useUserInfo } from "contexts/userInfo";

import patchUserProfile from "api/user/patchUserProfile";

const UserSettingsEditProfilePage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { userInfo, setUserInfo } = useUserInfo();
  const [userName, setUserName] = useState(userInfo["@air-fly_display_name"]);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });

  const verifyAccountHandler = () => {};

  const saveEditHandler = async () => {
    const token = userInfo["@air-fly_access-token"];
    const data = {
      displayName: userName,
    };

    const response = await patchUserProfile(token, data);

    if (response.status === 200) {
      setUserInfo("@air-fly_display_name", userName);
      handleToast({
        type: "success",
        title: "Name Change",
        description: "Congratulations, your name was changed successfully!",
      });
    } else {
      handleToast({
        type: "error",
        title: "Name Change",
        description: "An error occurred, and your name was not changed.",
      });
    }
  };

  const handleToast = ({ type, title, description }: IToast) => {
    setToastShow(true);
    setToastConfig({
      type,
      title,
      description,
    });
    setTimeout(() => {
      setToastShow(false);
    }, 5000);
  };

  return (
    <div className="user-settings-edit-profile">
      <PageTitle
        title={"Edit Profile"}
        subtitle={"Configuration"}
        hasCloseIcon={false}
        onBackClick={() => {
          setPrincipalScreen("/user-settings-profile");
        }}
        className="padding-hor-24"
      />
      <main className="user-settings-edit-profile__main margin-24">
        <div className="user-settings-edit-profile__input-container">
          <InputText
            label="Name"
            placeholder={userInfo["@air-fly_display_name"]}
            inputType="text"
            value={userName}
            onChangeInput={(e) => setUserName(e)}
          />

          {/* <InputText
            label="Username"
            placeholder={userInfo["@air-fly_display_name"]}
            inputType="text"
            value={userName}
            onChangeInput={(e) => setUserName(e)}
          /> */}
        </div>
        {/* <SubText>
          In most cases, you’ll be able to change your name back to{" "}
          {userInfo["@air-fly_display_name"]}.airfly for another 14 days.
        </SubText>
        <section className="profile-link-container margin-vert-24">
          <p className="profile-link-container__p no-margin">Profile</p>
          <p className="profile-link-container__p no-margin">
            https://airfly.vip/
            <span>{userInfo["@air-fly_display_name"].toLocaleLowerCase()}</span>
          </p>
        </section>
 
        <Button
          className="margin-top-40"
          buttonStyle="primary"
          onClick={saveEditHandler}
        >
          Done
        </Button> */}
        <Toast
          type={toastConfig.type}
          title={toastConfig.title}
          description={toastConfig.description}
          isVisible={toastShow}
          setIsVisible={setToastShow}
        />
      </main>
    </div>
  );
};

export default UserSettingsEditProfilePage;
