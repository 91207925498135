import fetchApi from "api/fetchApi";

const deleteSubscription = async (token: string) => {
  return await fetchApi({
    service: "checkout",
    endPoint: `/subscription`,
    method: "DELETE",
    token,
  });
};

export default deleteSubscription;
