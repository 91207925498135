export const subscriptionOptions = [
  {
    title: "AirFly Basic",
    price: "FREE",
    description: "Your starting point, where new connections begin to sprout.",
    value: "basic",
    benefits: [
      "Access up to 5 flight options, with destinations chosen randomly.",
      "Flights are based on departures from airports near you.",
      "No alert options included.",
    ],
  },
  {
    title: "AirFly Global",
    price: "$4,99",
    description: "Enjoy all features from Fly Basic, plus use our filters.",
    value: "global",
    benefits: [
      "View all flights departing from airports near you.",
      "Includes up to 5 alerts, limited to origin and destination only.",
      "No advanced filters available.",
    ],
  },
  {
    title: "AirFly Ultimate",
    price: "$9,99",
    description: "Enjoy all features from Fly Global, plus exclusive benefits.",
    value: "ultimate",
    benefits: [
      "Access all flights, with advanced filtering options.",
      "Receive unlimited alerts with detailed options, ensuring you never miss the perfect flight deal.",
    ],
  },
];
