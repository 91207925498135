import { useState } from "react";
import "./styles.scss";
import PageTitle from "components/PageTitles";
import Button from "../../components/Buttons/Button";
import { useDeskNavigation } from "contexts/useDeskNavigation";
import CodeAndPhoneNumber from "components/CodeAndPhoneNumber";
import Toast from "components/Toast";
import { IToast } from "types/toast";
import CodeVerification from "components/CodeVerification";
import SubText from "components/Texts/SubText";
import ClockIcon from "../../assets/icons/clocks/clock-icon-gray.svg";
import postPhoneNumberVerify from "api/phoneNumberVerify/postSendPhoneNumber";
import { useUserInfo } from "contexts/userInfo";
import postVerifyCode from "api/phoneNumberVerify/postVerifyCode";
import InputText from "components/Inputs/InputText";
import CloseIconPeach from "../../assets/icons/close/close-icon-peach.svg";

const AddPhoneNumberPage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { userInfo, setUserInfo } = useUserInfo();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberWarning, setPhoneNumberWarning] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [countryCodeWarning, setCountryCodeWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [messageCodeWarning, setMessageCodeWarning] = useState(false);
  const [codeValue, setCodeValue] = useState("");
  const [phoneCodeStage, setPhoneCodeStage] = useState<
    "add-phone-code" | "confirm-sms" | "show-phone"
  >("add-phone-code");

  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });

  const sendCodeHandler = async () => {
    setIsLoading(true);

    if (!countryCode.length) {
      setCountryCodeWarning(true);
      setToastConfig({
        description: "Add your country code to continue",
        title: "Empty Field",
        type: "info",
      });
      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
        setIsLoading(false);
        setCountryCodeWarning(false);
      }, 4000);
      return;
    }
    if (!phoneNumber.length) {
      setPhoneNumberWarning(true);
      setToastConfig({
        description: "Add your phone number to continue",
        title: "Empty Field",
        type: "info",
      });
      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
        setIsLoading(false);
        setPhoneNumberWarning(false);
      }, 4000);
      return;
    }
    const phoneCode = countryCode.replace("+", "") + phoneNumber;

    try {
      const request = await postPhoneNumberVerify(
        userInfo["@air-fly_access-token"],
        phoneCode
      );

      switch (request.status) {
        case 200:
          setPhoneCodeStage("confirm-sms");
          setToastConfig({
            description: "Verification code sent successfully",
            title: "Success!",
            type: "success",
          });
          setToastShow(true);

          setTimeout(() => {
            setToastShow(false);
            setIsLoading(false);
            setPhoneNumberWarning(false);
          }, 4000);
          break;

        default:
          setToastConfig({
            description: "Please check the fields and try again",
            title: "Code not sent",
            type: "error",
          });
          setToastShow(true);

          setTimeout(() => {
            setToastShow(false);
            setIsLoading(false);
            setPhoneNumberWarning(false);
          }, 4000);
          break;
      }
    } catch (error) {
      setToastConfig({
        description: "We had a problem making the request. Please try again",
        title: "Sorry",
        type: "error",
      });
      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
      }, 4000);
    } finally {
      setIsLoading(false);
    }
  };

  const verifyCode = async () => {
    if (codeValue.length < 5) {
      setMessageCodeWarning(true);
      setToastConfig({
        description: "You need to fill in the fields correctly",
        title: "Empty Field",
        type: "info",
      });
      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
        setIsLoading(false);
        setMessageCodeWarning(false);
      }, 4000);
      return;
    }

    try {
      setIsLoading(true);
      const phoneCode = countryCode.replace("+", "") + phoneNumber;

      const request = await postVerifyCode(userInfo["@air-fly_access-token"], {
        code: codeValue,
        phone: phoneCode,
      });

      switch (request.status) {
        case 200:
          setToastConfig({
            description: "Code verified Successfully",
            title: "Success!",
            type: "success",
          });
          setToastShow(true);

          setUserInfo("@air-fly_user_phone", phoneCode);
          setUserInfo("@air-fly_phone-verified", "true");

          setTimeout(() => {
            setToastShow(false);
            setIsLoading(false);
            setPhoneNumberWarning(false);
            setPrincipalScreen("/my-alerts");
          }, 4000);
          break;
        case 400:
          if (request.res.message.includes("400011")) {
            setMessageCodeWarning(true);
            setToastConfig({
              description: "Check the code sent and try again",
              title: "Invalid Code",
              type: "info",
            });
            setToastShow(true);

            setTimeout(() => {
              setToastShow(false);
              setIsLoading(false);
              setMessageCodeWarning(false);
            }, 4000);
          }
          break;
      }
    } catch (error) {
      setToastConfig({
        description: "We had a problem making the request. Please try again",
        title: "Sorry",
        type: "error",
      });
      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
      }, 4000);
    } finally {
      setIsLoading(false);
    }
  };

  const resendCode = () => {
    sendCodeHandler();
  };

  const connectAnotherNumberHandler = () => {};

  return (
    <div className="user-settings-account">
      <PageTitle
        title={"Alerts Settings"}
        subtitle={"manage notification"}
        onBackClick={() => {
          setPrincipalScreen("/my-alerts");
        }}
        hasCloseIcon={false}
        className="padding-hor-24"
      />
      <main className="user-settings-account__main padding-hor-24">
        {phoneCodeStage === "show-phone" && (
          <div className="main__verified-number">
            <h2 className="verified-number__title">Enable SMS Alerts</h2>
            <p className="verified-number__text">
              Enter your phone number to receive real-time flight updates
              directly to your phone. Stay updated and never miss an alert.
            </p>
            <div className="verified-number__input-button">
              <InputText
                inputType="tel"
                iconRight={CloseIconPeach}
                readOnly
                value={userInfo["@air-fly_user_phone"]}
              />
              <Button
                buttonStyle="secondary"
                onClick={connectAnotherNumberHandler}
              >
                Connect Another Number
              </Button>
              <SubText leftText="*" className="padding-hor-16">
                Connecting a new phone number will replace the existing phone
                number for your alerts. This cannot be undone.
              </SubText>
            </div>
          </div>
        )}

        {phoneCodeStage === "add-phone-code" && (
          <div className="main__code-phone-container">
            <h2 className="code-phone-container__text">Enable SMS Alerts</h2>
            <p className="code-phone-container__explanation">
              Enter your phone number to receive real-time flight updates
              directly to your phone. Stay updated and never miss an alert.
            </p>
            <CodeAndPhoneNumber
              setPhoneNumber={setPhoneNumber}
              setCountryCode={setCountryCode}
              isInputWarning={phoneNumberWarning}
              isSelectWarning={countryCodeWarning}
            />
            <Button
              disabled={isLoading}
              className="margin-vert-24"
              buttonStyle="primary"
              onClick={sendCodeHandler}
            >
              Enable SMS Alerts
            </Button>
          </div>
        )}
        {phoneCodeStage === "confirm-sms" && (
          <div>
            <CodeVerification
              valuesCodesCallback={setCodeValue}
              fieldsNumber={5}
              onEnterPress={verifyCode}
              isWarning={messageCodeWarning}
            />
            <SubText icon={ClockIcon} altIcon="clock icon">
              This code should arrive within 30 seconds. Didn’t receive the
              code?&ensp;
              <span className="resend-code" onClick={resendCode}>
                Resend new code.
              </span>
            </SubText>
            <Button
              disabled={isLoading || codeValue.length < 5}
              className="margin-vert-24"
              buttonStyle="primary"
              onClick={verifyCode}
            >
              Verify Code
            </Button>
          </div>
        )}
      </main>

      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default AddPhoneNumberPage;
