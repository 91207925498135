import fetchApi from "../fetchApi";

const getGameLeaderBoard = async (token: string, take?: string, skip?: string) => {
  return await fetchApi({
    service: "game",
    endPoint: `/leaderboard?take=${take}`,
    method: "GET",
    token,
  });
}

export default getGameLeaderBoard;
