import fetchApi from "../fetchApi";

const getGameQuestion = async (token: string,) => {
  return await fetchApi({
    service: "game",
    endPoint: `/question`,
    method: "GET",
    token,
  });
}

export default getGameQuestion;
