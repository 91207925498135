import { createContext, useContext, useState, ReactNode } from "react";
import { IUserInfo } from "types/userInfo";

interface IUserInfoContext {
  userInfo: IUserInfo;
  setUserInfo: (key: keyof IUserInfo, value: string) => void;
}

const UserInfoContext = createContext<IUserInfoContext | undefined>(undefined);

const UserInfoProvider = ({ children }: { children: ReactNode }) => {
  const initialUserInfo: IUserInfo = {
    "@air-fly_access-token":
      localStorage.getItem("@air-fly_access-token") || "",
    "@air-fly_user_avatar": localStorage.getItem("@air-fly_user_avatar") || "",
    "@air-fly_user_email": localStorage.getItem("@air-fly_user_email") || "",
    "@air-fly_display_name":
      localStorage.getItem("@air-fly_display_name") || "",
    "@air-fly_user_id": localStorage.getItem("@air-fly_user_id") || "",
    "@air-fly_newsletter": JSON.parse(
      localStorage.getItem("@air-fly_newsletter") || "false"
    ),
    "@air-fly_user_role": localStorage.getItem("@air-fly_user_role") || "",
    "@air-fly_user_country":
      localStorage.getItem("@air-fly_user_country") || "",
    "@air-fly_user_city": localStorage.getItem("@air-fly_user_city") || "",
    "@air-fly_geo_location_permission":
      localStorage.getItem("@air-fly_geo_location_permission") || "false",
    "@air-fly_user_lat": localStorage.getItem("@air-fly_user_lat") || "0",
    "@air-fly_user_lon": localStorage.getItem("@air-fly_user_lon") || "0",

    "@air-fly_user_phone": localStorage.getItem("@air-fly_user_phone") || "",
    "@air-fly_phone-verified":
      localStorage.getItem("@air-fly_phone-verified") || "",
    "@air-fly_user-plan": localStorage.getItem("@air-fly_user-plan") || "",
  };

  const [userInfo, setUserInfoState] = useState<IUserInfo>(initialUserInfo);

  const setUserInfo = (key: keyof IUserInfo, value: string) => {
    setUserInfoState((prevUserInfo) => ({
      ...prevUserInfo,
      [key]: value,
    }));

    localStorage.setItem(key, value);
  };

  return (
    <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </UserInfoContext.Provider>
  );
};

const useUserInfo = () => {
  const context = useContext(UserInfoContext);

  if (context === undefined) {
    throw new Error("useUserInfo must be used within a UserInfoProvider");
  }

  return context;
};

export { UserInfoProvider, useUserInfo };
