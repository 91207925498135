import "./styles.scss";

import PageTitle from "components/PageTitles";
import ListItemLink from "components/Lists/ListItemLink";

import { useDeskNavigation } from "contexts/useDeskNavigation";

import accountIconGray from "../../assets/icons/gear/gear-icon-gray.svg";
import profileIconGray from "../../assets/icons/avatars/profile-icon-gray.svg";
import startGray from "../../assets/icons/stars/star-icon-gray.svg";
import bellGray from "../../assets/icons/bells/bell-gray.svg";

const UserSettingsPage: React.FC = () => {
  const { setPrincipalScreen, setSecondaryScreen } = useDeskNavigation();

  const userSettingsItems = [
    {
      labelContent: "Account",
      iconLeftContent: accountIconGray,
      clickAction: () => {
        setPrincipalScreen("/user-settings-account");
        setSecondaryScreen("");
      },
      isActive: true,
    },
    {
      labelContent: "Profile",
      iconLeftContent: profileIconGray,
      clickAction: () => {
        setPrincipalScreen("/user-settings-profile");
        setSecondaryScreen("");
      },
      isActive: true,
    },
    {
      labelContent: "Subscription",
      iconLeftContent: startGray,
      clickAction: () => {
        setPrincipalScreen("/user-settings-subscriptions");
        setSecondaryScreen("");
      },
      isActive: true,
    },
    {
      labelContent: "Alerts Settings",
      iconLeftContent: bellGray,
      clickAction: () => {
        setPrincipalScreen("/user-settings-alerts");

        setSecondaryScreen("");
      },
      isActive: true,
    },
  ];

  return (
    <div className="user-settings">
      <PageTitle
        title={"Settings"}
        subtitle={"Configuration"}
        onBackClick={() => {
          setPrincipalScreen("/search-milesy");
          setSecondaryScreen("");
        }}
        className="padding-hor-24"
      />

      <div className="list-container-settings margin-hor-24">
        {userSettingsItems
          .filter((item) => item.isActive)
          .map((item, index, arr) => (
            <ListItemLink
              key={index}
              onClick={item.clickAction}
              hasArrowRight
              imageLeft={item.iconLeftContent}
              isImageCircle
              highlightText={item.labelContent}
              hasSeparator={arr.length > index + 1}
            />
          ))}
      </div>
    </div>
  );
};

export default UserSettingsPage;
