import React, { useEffect, useState } from "react";
import InputText from "../Inputs/InputText";
import codesMock from "../../mocks/countryCodes.json";
import "./styles.scss";
import { CountryCode } from "libphonenumber-js";

import { setupPhoneNumberInput } from "utils/masks";
import Select from "components/Selects/Select";

interface CodeAndPhoneNumberProps {
  setPhoneNumber: (a: string) => void;
  setCountryCode: (a: string) => void;
  isInputWarning?: boolean;
  isSelectWarning?: boolean;
  className?: string;
}

const CodeAndPhoneNumber: React.FC<CodeAndPhoneNumberProps> = (props) => {
  const {
    setPhoneNumber,
    setCountryCode,
    isInputWarning,
    isSelectWarning,

    className,
  } = props;
  const [phoneCode, setPhoneCode] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  useEffect(() => {
    setCountryCode(phoneCode.replace("+", ""));
  }, [phoneCode]);

  const inputPhoneHandler = (e: string) => {
    if (phoneCode) {
      let codeCountry = JSON.parse(phoneCode);

      const formattedNumber = setupPhoneNumberInput(
        codeCountry.code as CountryCode,
        e
      );

      setPhone(formattedNumber);

      setCountryCode(codeCountry.countryCode);
      setPhoneNumber(`${e.replace(/\D/g, "")}`);
    } else {
      setPhone(e);
      setPhoneNumber(e.replace(/\D/g, ""));
    }
  };

  return (
    <div id="code-and-phone-number" className={className ?? ""}>
      <Select
        options={codesMock.map((code) => {
          return {
            label: `${code.flag} ${code.code} ${code.countryCode}`,
            value: JSON.stringify(code),
          };
        })}
        onChange={setPhoneCode}
        defaultValueText={"Select"}
        isWarning={isSelectWarning}
        className="country-code-phone"
      />
      <InputText
        value={phone}
        onChangeInput={inputPhoneHandler}
        placeholder="Phone number"
        inputType="tel"
        isWarning={isInputWarning}
        className="margin-top-4"
      />
    </div>
  );
};

export default CodeAndPhoneNumber;
