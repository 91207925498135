import { createContext, ReactNode, useContext, useState } from "react";
import { FlightAvailability } from "types/flights";

interface UseSearchedFlightProps {
  searchedFlight: FlightAvailability;
  setSearchedFlight: (flight: FlightAvailability) => void;
}

const SearchedFlightContext = createContext<UseSearchedFlightProps>(
  {} as UseSearchedFlightProps
);

const SearchedFlightProvider = ({ children }: { children: ReactNode }) => {
  const [searchedFlight, setSearchedFlight] = useState<FlightAvailability>(
    {} as FlightAvailability
  );

  return (
    <SearchedFlightContext.Provider
      value={{ searchedFlight, setSearchedFlight }}
    >
      {children}
    </SearchedFlightContext.Provider>
  );
};

const useSearchedFlight = () => {
  const context = useContext(SearchedFlightContext);

  if (!context) {
    throw new Error(
      "useSearchedFlight must be used within a SearchedFlightProvider"
    );
  }

  return context;
};

export { SearchedFlightProvider, useSearchedFlight };
