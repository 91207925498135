import fetchApi from "../fetchApi";

export type GetAirportsCodesParams = {
  search?: string;
  iata?: string;
  orderBy?: "iata" | "name" | "city" | "country";
  order?: "asc" | "desc";
  take?: number;
  skip?: number;
};

const getAirportsCodes = async (
  token: string,
  queryParams?: Partial<GetAirportsCodesParams>,
  tokenQueryParam?: string
) => {
  const params: Record<string, any> = {};
  if (queryParams?.search || queryParams?.search !== undefined) {
    params.search = queryParams?.search;
  }
  if (queryParams?.iata || queryParams?.iata !== undefined) {
    params.iata = queryParams?.iata;
  }
  if (queryParams?.orderBy || queryParams?.orderBy !== undefined) {
    params.orderBy = queryParams?.orderBy;
  }
  if (queryParams?.order || queryParams?.order !== undefined) {
    params.order = queryParams?.order;
  }
  if (queryParams?.take || queryParams?.take !== undefined) {
    params.take = queryParams?.take;
  }
  if (queryParams?.skip || queryParams?.skip !== undefined) {
    params.skip = queryParams?.skip;
  }

  return await fetchApi({
    service: "flights",
    endPoint: `/airport-codes${tokenQueryParam ? "/" + tokenQueryParam : ""}`,
    method: "GET",
    token,
    params,
  });
};

export default getAirportsCodes;
