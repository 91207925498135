import fetchApi from "../fetchApi";

const getAirports = async (token: string) => {
  return await fetchApi({
    service: "airports",
    endPoint: "",
    method: "GET",
    token,
  });
};

export default getAirports;
