import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

import ButtonIcon from "components/Buttons/ButtonIcon";
import InputTextSelect, {
  IOptionInputTextSelectProps,
} from "components/Inputs/InputTextSelect";

import { useDeskNavigation } from "contexts/useDeskNavigation";
import { useIconContext } from "contexts/useIconNav";

import { IAirportsSearch } from "types/airportsSearch";

import AirFlyLogo from "../../../assets/logos/air-fly-logo-1.svg";
import leftArrowGray from "../../../assets/icons/arrows/chevron-left-gray.svg";
import Button from "components/Buttons/Button";

interface HeaderNavigateProps {
  className?: string;
  navigateText?: string;
  navigateTo?: string;
  clickAvatarCallback?: (a: any) => void;
  inputSearchResultOptions?: IOptionInputTextSelectProps[];
  handleSelectChange?: (search: string) => void;
  inputPlaceholder?: string;
  inputIconRight?: string;
  onChangeInput?: (search: string) => void;
  userAvatarImage: string;
  showInput?: boolean;
  inputSearchClick?: (a: any) => void;
  onClickIconRight?: (a: any) => void;
  showLogo?: boolean;
  hasIconRightButton?: boolean;
  searchInput?: boolean;
  isALinkHeader?: boolean;
  clickOnLogo?: (a: any) => void;
  onClickLogin?: (a: any) => void;
}

const HeaderNavigate: React.FC<HeaderNavigateProps> = (props) => {
  const {
    className,
    navigateText,
    navigateTo,
    clickAvatarCallback,
    handleSelectChange,
    inputIconRight,
    inputPlaceholder,
    inputSearchResultOptions,
    onChangeInput,
    userAvatarImage,
    showInput,
    inputSearchClick,
    onClickIconRight,
    showLogo,
    hasIconRightButton,
    searchInput,
    isALinkHeader,

    clickOnLogo,
    onClickLogin,
  } = props;

  const navigate = useNavigate();
  const {
    setMainScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();
  const [inputSearch, setInputSearch] = useState("");
  const [optionsSelect, setOptionsSelect] = useState<
    IOptionInputTextSelectProps[]
  >([]);
  const [pageWidthSize, setPageWidthSize] = useState(window.innerWidth);
  const [expandSearchMobile, setExpandSearchMobile] = useState(false);

  useEffect(() => {
    if (inputSearchResultOptions) {
      setOptionsSelect(inputSearchResultOptions);
    }
  }, [inputSearchResultOptions]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setPageWidthSize(window.innerWidth);
    });
  }, []);

  const listFlights = () => {
    setMainScreen("");
    setPrincipalScreen("/search-milesy");
    setSecondaryScreen("");
    setTertiaryScreen("");
    if (onChangeInput) {
      onChangeInput("");
      headerOnChange("");
      setOptionsSelect([]);
    }
  };

  const listFavoriteFlights = () => {
    setMainScreen("");
    setPrincipalScreen("/favorites-flies");
    setSecondaryScreen("");
    setTertiaryScreen("");
  };

  const headerChangeInput = (event: string) => {
    if (onChangeInput) {
      onChangeInput(event);
    }
  };

  const headerOnChange = (event: string) => {
    if (event !== "" && handleSelectChange) {
      const iata: IAirportsSearch = JSON.parse(event);

      handleSelectChange(iata?.iata);
    } else if (handleSelectChange) {
      handleSelectChange("");
    }
  };

  const alertHandler = () => {
    setMainScreen("");
    setPrincipalScreen("/my-alerts");
    setSecondaryScreen("");
    setTertiaryScreen("");
  };

  const airportQuizHandler = () => {
    setMainScreen("/airport-quiz");
    setPrincipalScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
  };

  const { handleButtonClick, getIconClass } = useIconContext();

  const inputButtonsRenderer = (size: "mobile" | "wide") => {
    if (size === "mobile") {
      return (
        <>
          {inputSearchResultOptions &&
            handleSelectChange &&
            onChangeInput &&
            showInput &&
            !isALinkHeader && (
              <div
                className={`header-navigate-default__search-wrapper--${size}`}
              >
                <ButtonIcon
                  icon="icon-grid"
                  buttonStyle="tertiary"
                  iconClass={getIconClass("grid")}
                  className={`header-navigate-btn ${
                    expandSearchMobile ? "hidden" : ""
                  }`}
                  onClick={() => handleButtonClick("grid", listFlights)}
                />
                <ButtonIcon
                  icon="icon-heart-outline"
                  buttonStyle="tertiary"
                  iconClass={getIconClass("heart-outline")}
                  className={`header-navigate-btn ${
                    expandSearchMobile ? "hidden" : ""
                  }`}
                  onClick={() =>
                    handleButtonClick("heart-outline", listFavoriteFlights)
                  }
                />
                <InputTextSelect
                  className={`header-navigate-default__input-text-select ${
                    expandSearchMobile ? "expand" : ""
                  }`}
                  options={optionsSelect}
                  onSelect={headerOnChange}
                  onChangeInput={headerChangeInput}
                  inputPlaceholder={inputPlaceholder}
                  iconRight={inputIconRight}
                  onClick={inputSearchClick}
                  onFocus={() => setExpandSearchMobile((prev) => !prev)}
                  onBlur={() => setExpandSearchMobile((prev) => !prev)}
                  onClickIconRight={onClickIconRight}
                  hasIconRightButton={hasIconRightButton}
                  searchInput={searchInput}
                  setInputValue={setInputSearch}
                  inputValue={inputSearch}
                />
                <ButtonIcon
                  icon="icon-notification"
                  buttonStyle="tertiary"
                  iconClass={getIconClass("notification")}
                  className={`header-navigate-btn ${
                    expandSearchMobile ? "hidden" : ""
                  }`}
                  onClick={() =>
                    handleButtonClick("notification", alertHandler)
                  }
                />
                <ButtonIcon
                  icon="icon-flag"
                  buttonStyle="tertiary"
                  iconClass={getIconClass("flag")}
                  className={`header-navigate-btn ${
                    expandSearchMobile ? "hidden" : ""
                  }`}
                  onClick={() => handleButtonClick("flag", airportQuizHandler)}
                />
              </div>
            )}
        </>
      );
    } else {
      return (
        <>
          {inputSearchResultOptions &&
            handleSelectChange &&
            onChangeInput &&
            !isALinkHeader && (
              <div
                className={`header-navigate-default__search-wrapper--${size}`}
              >
                <ButtonIcon
                  icon="icon-grid"
                  buttonStyle="tertiary"
                  iconClass={getIconClass("grid")}
                  className={`header-navigate-btn ${
                    expandSearchMobile ? "hidden" : ""
                  }`}
                  onClick={() => handleButtonClick("grid", listFlights)}
                />
                <ButtonIcon
                  icon="icon-heart-outline"
                  buttonStyle="tertiary"
                  iconClass={getIconClass("heart-outline")}
                  className={`header-navigate-btn ${
                    expandSearchMobile ? "hidden" : ""
                  }`}
                  onClick={() =>
                    handleButtonClick("heart-outline", listFavoriteFlights)
                  }
                />
                <InputTextSelect
                  setInputValue={setInputSearch}
                  inputValue={inputSearch}
                  className={`header-navigate-default__input-text-select ${
                    expandSearchMobile ? "expand" : ""
                  }`}
                  options={optionsSelect}
                  onSelect={headerOnChange}
                  onChangeInput={headerChangeInput}
                  inputPlaceholder={inputPlaceholder}
                  iconRight={inputIconRight}
                  onClick={inputSearchClick}
                  onClickIconRight={onClickIconRight}
                  hasIconRightButton={hasIconRightButton}
                />
                <ButtonIcon
                  icon="icon-notification"
                  buttonStyle="tertiary"
                  iconClass={getIconClass("notification")}
                  className={`header-navigate-btn ${
                    expandSearchMobile ? "hidden" : ""
                  }`}
                  onClick={() =>
                    handleButtonClick("notification", alertHandler)
                  }
                />
                <ButtonIcon
                  icon="icon-flag"
                  buttonStyle="tertiary"
                  iconClass={getIconClass("flag")}
                  className={`header-navigate-btn ${
                    expandSearchMobile ? "hidden" : ""
                  }`}
                  onClick={() => handleButtonClick("flag", airportQuizHandler)}
                />
              </div>
            )}
        </>
      );
    }
  };

  return (
    <header id="header-navigate-default" className={className ?? ""}>
      {showLogo && (
        <div className="header-wrapper">
          <div
            className="icon-container airFly-logo-text"
            onClick={clickOnLogo}
          >
            <img src={AirFlyLogo} alt="airFly logo" />
          </div>
          {inputButtonsRenderer("wide")}
          <div className="profile-user-container">
            {isALinkHeader ? (
              <Button onClick={onClickLogin} buttonStyle="primary">
                Login
              </Button>
            ) : (
              <img
                src={userAvatarImage}
                alt="user avatar"
                onClick={clickAvatarCallback}
              />
            )}
          </div>
        </div>
      )}

      {navigateTo && (
        <div
          className="button-navigate-page margin-bottom-16"
          onClick={() => navigate(navigateTo)}
        >
          <img src={leftArrowGray} alt="left arrow" />
          <p>{navigateText}</p>
        </div>
      )}

      {inputButtonsRenderer("mobile")}
    </header>
  );
};

export default HeaderNavigate;
