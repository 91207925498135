import fetchApi from "../fetchApi";

const deleteUserAlert = async (token: string, alertId: string) => {
  return await fetchApi({
    service: "alerts",
    endPoint: `/${alertId}`,
    method: "DELETE",
    token,
  });
};

export default deleteUserAlert;
