import { createContext, ReactNode, useContext, useState } from "react";
import { AirportPin } from "types/airportsPins";

interface UseAirportsShowPinProps {
  airportsPins: AirportPin[];
  setAirportsPins: (flight: AirportPin[]) => void;
}

const AirportsPinsContext = createContext<UseAirportsShowPinProps>(
  {} as UseAirportsShowPinProps
);

const AirportsPinsProvider = ({ children }: { children: ReactNode }) => {
  const [airportsPins, setAirportsPins] = useState<AirportPin[]>(
    {} as AirportPin[]
  );

  return (
    <AirportsPinsContext.Provider value={{ airportsPins, setAirportsPins }}>
      {children}
    </AirportsPinsContext.Provider>
  );
};

const usePinsAirports = () => {
  const context = useContext(AirportsPinsContext);

  if (!context) {
    throw new Error(
      "usePinsAirports must be used within a AirportsPinsProvider"
    );
  }

  return context;
};

export { usePinsAirports, AirportsPinsProvider };
