import React, { useState } from "react";
import "./styles.scss";

interface InputRadioProps {
  elements: InputRadioElement[];
  onChangeValueCallback: (a: string[]) => void;
  selectedValue: string | string[] | undefined;
  className?: string;
  showInputRadio?: boolean;
}

interface InputRadioElement {
  inputLabel: string;
  inputValue: string;
  groupName: string;
}

const InputRadio: React.FC<InputRadioProps> = (props) => {
  const {
    elements,
    onChangeValueCallback,
    selectedValue,
    className,
    showInputRadio,
  } = props;

  return (
    <div id="input-radio-default" className={className ?? ""}>
      {elements.map((element, index) => (
        <div className="label-input-container margin-right-8" key={index}>
          <input
            checked={selectedValue === element.inputValue}
            className={
              showInputRadio ? "input-radio" : "input-radio-no-display"
            }
            type="radio"
            id={`${element.inputLabel}-${index}`}
            name={element.groupName}
            value={element.inputValue}
            onChange={(e) => onChangeValueCallback([e.target.value])}
          />
          <label
            className="no-margin"
            htmlFor={`${element.inputLabel}-${index}`}
          >
            {element.inputLabel}
          </label>
        </div>
      ))}
    </div>
  );
};

export default InputRadio;
