import { useState } from "react";
import "./styles.scss";

import PageTitle from "../../components/PageTitles";
import SubText from "../../components/Texts/SubText";
import InputText from "../../components/Inputs/InputText";

import { useDeskNavigation } from "contexts/useDeskNavigation";

import chevronRightIcon from "../../assets/icons/arrows/bx-chevron-right.svg";
import InfoIcon from "../../assets/icons/info/info-gray.svg";

const UserSettingsAppLanguagePage: React.FC = () => {
  const [residence, setResidence] = useState("Brazil");
  const [language, setLanguage] = useState("English");

  const { setPrincipalScreen, setSecondaryScreen } = useDeskNavigation();

  return (
    <div className="user-settings-language">
      <PageTitle
        title={"Language & Location"}
        subtitle={"Preferences"}
        hasCloseIcon={false}
        onBackClick={() => {
          setPrincipalScreen("/user-settings");
          setSecondaryScreen("/user-settings-account");
        }}
        className="padding-hor-24"
      />
      <div className="margin-hor-24">
        <InputText
          label="Legal Residence"
          iconRight={chevronRightIcon}
          value={residence}
          onChangeInput={(e) => setResidence(e)}
          inputType="text"
        />
        <SubText icon={InfoIcon} altIcon="info icon">
          If you want to change your country, please contact{" "}
          <span className="anchor-link">customer support</span>
        </SubText>
        <InputText
          label="Default Language"
          iconRight={chevronRightIcon}
          value={language}
          onChangeInput={(e) => setLanguage(e)}
          inputType="text"
        />
      </div>
    </div>
  );
};

export default UserSettingsAppLanguagePage;
