import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import "./styles.scss";
import InputText from "../InputText";
import ListItemLink from "components/Lists/ListItemLink";

interface InputTextSelectProps {
  className?: string;
  disabled?: boolean;
  options: IOptionInputTextSelectProps[];
  onSelect: (a: any) => void;
  iconRight?: string;
  onChangeInput: (search: string) => void;
  inputPlaceholder?: string;
  onClick?: (e: any) => void;
  onClickIconRight?: (a: any) => void;
  hasIconRightButton?: boolean;
  searchInput?: boolean;
  isWarning?: boolean;
  inputValue: string;
  setInputValue: (a: any) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

export interface IOptionInputTextSelectProps {
  label: string;
  detailsText?: string;
  value: string;
  disabled?: boolean;
  optionImage?: string;
}

const InputTextSelect: React.FC<InputTextSelectProps> = (props) => {
  const {
    onBlur,
    onFocus,
    className,
    disabled,
    options,
    onSelect,
    iconRight,
    inputPlaceholder,
    onChangeInput,
    onClick,
    onClickIconRight,
    hasIconRightButton,
    searchInput,
    isWarning,
    inputValue,
    setInputValue,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  // const [inputValue, setInputValue] = useState("");
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionSelect = (option: IOptionInputTextSelectProps) => {
    setInputValue(option.label);
    onSelect(option.value);
    setIsOpen(false);
    onChangeInput(option.value);
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
    setIsOpen(true);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const timeout = setTimeout(() => {
      onChangeInput(value);
    }, 300);

    setTypingTimeout(timeout);
  };

  return (
    <div id="input-text-select" className={className ?? ""} ref={dropdownRef}>
      <InputText
        placeholder={inputPlaceholder}
        searchInput={searchInput}
        value={inputValue}
        onChangeInput={handleInputChange}
        inputType="text"
        onFocus={onFocus}
        className="input-text-select__input"
        iconRight={iconRight}
        onClick={onClick}
        onClickIconRight={onClickIconRight}
        hasIconRightButton={hasIconRightButton}
        isWarning={isWarning}
        onBlur={onBlur}
      />

      {isOpen && !disabled && options.length > 0 && (
        <div className="input-text-select__dropdown-wrapper">
          <ul className="input-text-select__dropdown-list-ul no-padding">
            {options.map((option, index) => (
              <li key={index} className="input-text-select__dropdown-list-li">
                <ListItemLink
                  highlightText={option.label}
                  simpleText={option.detailsText}
                  hasArrowRight
                  onClick={() => handleOptionSelect(option)}
                  backgroundColor="var(--ghost)"
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default InputTextSelect;
