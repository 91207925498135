import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

import HeaderNavigate from "components/Headers/HeaderNavigate";
import infoIcon from "../../assets/icons/info/union-circle-gray.svg";
import CheckCircleGreen from "../../assets/icons/checks/check-circle-green.svg";

import { useUserInfo } from "contexts/userInfo";
import { subscriptionOptions } from "mocks/subscriptionPlans";
import InputRadioSub from "components/Inputs/InputRadioSub";
import Button from "components/Buttons/Button";
import postVerifyEmailToken from "api/emailVerification/postVerifyEmailToken";
import { useModal } from "contexts/useModal";
import Loading from "components/Loading";
import ErrorCheck from "../../assets/icons/close/close-icon-pink.svg";

const VerifyEmailPage: React.FC = () => {
  const { userInfo } = useUserInfo();
  const [chosenType, setChosenType] = useState("basic");
  const [emailVerifyStatus, setEmailVerifyStatus] = useState<
    "success" | "error" | "already-verified" | undefined
  >(undefined);
  const { setModalContent, setOpenUseModal, openUseModal } = useModal();

  const navigate = useNavigate();

  useEffect(() => {
    setModalContent(
      <section className="loading-modal">
        <Loading />
      </section>
    );
    setOpenUseModal(true);
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    if (token) {
      verifyEmailToken(token);
    } else {
      setOpenUseModal(false);

      navigate("/");
    }
  }, []);

  const verifyEmailToken = async (token: string) => {
    try {
      const request = await postVerifyEmailToken(
        userInfo["@air-fly_access-token"],
        token
      );

      switch (request.status) {
        case 200:
          setOpenUseModal(false);

          setEmailVerifyStatus("success");

          break;
        case 400:
          if (request.res.message.includes("400006")) {
            setEmailVerifyStatus("already-verified");

            setOpenUseModal(false);
          }

          break;
        default:
          setEmailVerifyStatus("error");
          setOpenUseModal(false);
          break;
      }
    } catch (error) {
      setEmailVerifyStatus("error");

      setOpenUseModal(false);
    }
  };

  const handleOptionChange = (value: string) => {
    setChosenType(value);
  };

  return (
    <div className="verify-email-page">
      <main className="verify-email-page__main margin-hor-24">
        {!openUseModal && (
          <>
            {emailVerifyStatus === "error" ? (
              <>
                <div className="main__verify-email-message">
                  <img
                    src={ErrorCheck}
                    alt="error check"
                    className="status-request"
                  />
                  <h5 className="main__verify-email-message--title">
                    Unable to verify your email.
                  </h5>
                  <p className="small-p main__verify-email-message--explanation-text">
                    Please check the link sent to your email and try again, or
                    try to send email again.
                  </p>
                  <Button
                    buttonStyle="primary"
                    onClick={() => navigate("/app")}
                  >
                    Login
                  </Button>
                </div>
              </>
            ) : emailVerifyStatus === "already-verified" ? (
              <>
                <div className="main__verify-email-message">
                  <h5 className="main__verify-email-message--title">
                    Your email has already been verified
                  </h5>
                  <p className="small-p main__verify-email-message--explanation-text">
                    Please check the link sent to your email and try again
                  </p>
                  <Button
                    buttonStyle="primary"
                    onClick={() => navigate("/app")}
                  >
                    Login
                  </Button>
                </div>
              </>
            ) : emailVerifyStatus === "success" ? (
              <>
                <div className="main__verify-email-message">
                  <img
                    src={CheckCircleGreen}
                    alt="check"
                    className="status-request"
                  />

                  <h5 className="main__verify-email-message--title">
                    Email verified successfully!
                  </h5>
                  <p className="small-p main__verify-email-message--explanation-text">
                    Take the opportunity to upload it to your monthly plan and
                    get more benefits and get ahead!
                  </p>
                </div>

                <div className="main__plans">
                  {subscriptionOptions.map((option, index) => (
                    <InputRadioSub
                      key={index}
                      title={option.title}
                      price={option.price}
                      description={option.description}
                      value={option.value}
                      icon={infoIcon}
                      groupName={"options"}
                      checked={option.value === chosenType}
                      onChange={handleOptionChange}
                      benefits={option.benefits}
                    />
                  ))}
                  <Button
                    buttonStyle="primary"
                    onClick={() => navigate("/app")}
                  >
                    Subscribe
                  </Button>

                  <Button
                    buttonStyle="quintenary"
                    onClick={() => navigate("/app")}
                  >
                    I'll do it later
                  </Button>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </main>
    </div>
  );
};

export default VerifyEmailPage;
