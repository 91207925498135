import React, { useEffect } from "react";
import "./styles.scss";
import L from "leaflet";
import Sphere from "../../assets/icons/spheres/sphere-item-icon-orange-white.svg";
import Square from "../../assets/icons/squares/square-orange.svg";
import { AirportPin } from "types/airportsPins";

interface MapRouteProps {
  className?: string;
  originCoordinates?: ICoordinates;
  destinyCoordinates?: ICoordinates;
  airportsPinsList: AirportPin[];
}

export type ICoordinates = {
  city?: string;
  iataCode?: string;
  lat: number;
  long: number;
};

const MapRoute: React.FC<MapRouteProps> = ({
  className,
  originCoordinates,
  destinyCoordinates,
  airportsPinsList,
}) => {
  console.log(airportsPinsList);

  useEffect(() => {
    const map = L.map("map", {
      minZoom: 2,
      maxZoom: 100,
      worldCopyJump: true,
      doubleClickZoom: false,
      touchZoom: false,
    });

    const originIcon = L.icon({
      iconUrl: Sphere,
      iconSize: [12, 12],
      iconAnchor: [6, 6],
      popupAnchor: [0, -6],
    });
    const destinationIcon = L.icon({
      iconUrl: Square,
      iconSize: [12, 12],
      iconAnchor: [6, 6],
      popupAnchor: [0, -6],
    });

    L.tileLayer(
      "https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
    ).addTo(map);

    // Remover duplicados com base nas coordenadas (lat, long)
    if (airportsPinsList?.length) {
      const uniquePins = Array.from(
        new Map(
          airportsPinsList.map((pin) => [`${pin.lat},${pin.long}`, pin])
        ).values()
      );

      uniquePins.forEach((pin) => {
        const marker = L.marker([pin.lat, pin.long], { icon: destinationIcon })
          .addTo(map)
          .bindTooltip(pin.htmlToolTip || "", {
            permanent: true,
            direction: "top",
          })
          .bindPopup(pin.htmlPopUp || "");

        marker.on("mouseover", () => {
          marker.openPopup();
          marker.closeTooltip();
        });

        marker.on("mouseout", () => {
          marker.closePopup();
          marker.openTooltip();
        });
      });
    }

    // Adicionando marcadores de origem e destino, se existirem
    if (
      originCoordinates?.lat &&
      originCoordinates?.long &&
      destinyCoordinates?.lat &&
      destinyCoordinates?.long
    ) {
      const originMarker = L.marker(
        [originCoordinates.lat, originCoordinates.long],
        { icon: originIcon }
      ).addTo(map);

      const destinyMarker = L.marker(
        [destinyCoordinates.lat, destinyCoordinates.long],
        { icon: destinationIcon }
      ).addTo(map);

      const route: [number, number][] = [
        [originCoordinates.lat, originCoordinates.long],
        [destinyCoordinates.lat, destinyCoordinates.long],
      ];

      L.polyline(route, {
        color: "#eb6300",
        weight: 3,
        dashArray: "5, 10",
      }).addTo(map);

      map.fitBounds(route);
    } else if (originCoordinates?.lat && originCoordinates?.long) {
      map.setView([0, 0], 2.5);
    }

    return () => {
      map.remove();
    };
  }, [originCoordinates, destinyCoordinates, airportsPinsList]);

  return (
    <section id="map-route-component" className={className ?? ""}>
      <div id="map"></div>
    </section>
  );
};

export default MapRoute;
