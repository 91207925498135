import fetchApi from "api/fetchApi";

const deleteFavoriteFlight = async (token: string, flightId?: string) => {
  return await fetchApi({
    service: "users",
    endPoint: `/favorite/${flightId}`,
    method: "DELETE",
    token,
  });
};

export default deleteFavoriteFlight;
