import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

import PageTitle from "../../components/PageTitles";
import Button from "../../components/Buttons/Button";
import ListItemToggle from "../../components/Lists/ListItemToggle";
import Toast from "components/Toast";

import { IToast } from "types/toast";

import { useLoginSignUpNavigation } from "contexts/useLoginSignUpNavigation";
import { useDeskNavigation } from "contexts/useDeskNavigation";
import { useUserInfo } from "contexts/userInfo";

import deleteUserId from "api/user/deleteUsersId";
import patchUserProfile from "api/user/patchUserProfile";

import ClockIconGray from "../../assets/icons/clocks/clock-icon-gray.svg";
import Mail from "../../assets/icons/socialMedia/mail.svg";

const UserSettingsManageAccountPage: React.FC = () => {
  const { userInfo } = useUserInfo();
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const { setComponent } = useLoginSignUpNavigation();
  const navigate = useNavigate();
  const { setPrincipalScreen, setSecondaryScreen, setTertiaryScreen } =
    useDeskNavigation();

  const takeABreakHandler = (event: boolean) => {};

  const changeReceiveNewsletter = async (e: boolean) => {
    const token = userInfo["@air-fly_access-token"];
    const data = {
      newsLetter: e,
    };

    const response = await patchUserProfile(token, data);

    if (response.status === 200) {
      handleToast({
        type: "success",
        title: "Newsletter Change",
        description: data.newsLetter
          ? "Congratulations, your newsletter subscription was successfully updated!"
          : "Newsletter subscription disabled. You will no longer receive updates.",
      });
    } else {
      handleToast({
        type: "error",
        title: "Newsletter Change",
        description:
          "An error occurred, and your receive newsletter was not changed.",
      });
    }
  };

  const handleToast = ({ type, title, description }: IToast) => {
    setToastShow(true);
    setToastConfig({
      type,
      title,
      description,
    });
    setTimeout(() => {
      setToastShow(false);
    }, 5000);
  };

  const blockRequestsHandler = () => {};

  const deleteAccountHandler = async () => {
    const token = userInfo["@air-fly_access-token"];
    const userId = userInfo["@air-fly_user_id"];

    try {
      const deleteResponse = await deleteUserId(userId);
      if (deleteResponse.status === 204) {
        localStorage.clear();
        sessionStorage.clear();
        setPrincipalScreen("");
        setSecondaryScreen("");
        setTertiaryScreen("");
        setComponent("");
        navigate("/");
      } else {
        console.error(
          "Unexpected response from delete operation",
          deleteResponse
        );
      }
    } catch (error) {
      console.error("There was an error during the delete operation:", error);
    }
  };

  return (
    <div className="user-settings-manage-account ">
      <PageTitle
        title={"Manage Account"}
        subtitle={"Account Security"}
        hasCloseIcon={false}
        onBackClick={() => {
          setPrincipalScreen("/user-settings-account");
        }}
        className="padding-hor-24"
      />

      <div className="list-container-settings margin-hor-24">
        <ListItemToggle
          toggleInitialState
          hasSeparator
          highlightText="Account"
          simpleText="Deactivate my account for 7 days"
          isImageCircle
          imageLeft={ClockIconGray}
          toggleCallBack={takeABreakHandler}
        />
        <ListItemToggle
          toggleInitialState={Boolean(userInfo["@air-fly_newsletter"])}
          hasSeparator
          highlightText="Newsletter"
          simpleText="Deactivate receiving newsletter updates"
          isImageCircle
          imageLeft={Mail}
          toggleCallBack={changeReceiveNewsletter}
        />
        <Button buttonStyle="quaternary" onClick={deleteAccountHandler}>
          Delete Account
        </Button>
      </div>

      <Toast
        type={toastConfig.type}
        title={toastConfig.title}
        description={toastConfig.description}
        isVisible={toastShow}
        setIsVisible={setToastShow}
      />
    </div>
  );
};

export default UserSettingsManageAccountPage;
