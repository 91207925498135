import React, { ReactNode, useEffect, useState } from "react";
import "./styles.scss";
import MapRoute, { ICoordinates } from "components/MapRoute";
import HeaderNavigate from "components/Headers/HeaderNavigate";
import FilterIconGray from "../../../assets/icons/filter/filter-icon-gray.svg";
import { IOptionInputTextSelectProps } from "components/Inputs/InputTextSelect";
import getAirportsCodes from "api/flights/getAirportsCodes";
import { useUserInfo } from "contexts/userInfo";
import { IAirportsSearch } from "types/airportsSearch";
import { useSearchOriginDestiny } from "contexts/searchOriginDestiny";
import { AirportPin } from "types/airportsPins";
import getAirports from "api/airports/getAirports";
import SlidUpFilterFlights, {
  FilterObjectSlidUp,
} from "components/Modal/SlidUpFilterFlights";
import { useModal } from "contexts/useModal";
import ModalFilterFlights from "components/Modal/ModalFilterFlights";
import Button from "components/Buttons/Button";
import { useDeskNavigation } from "contexts/useDeskNavigation";

interface DeskProps {
  hasANewMessage: boolean;
  majorMain?: ReactNode;
  principalMain?: ReactNode;
  secondaryMain?: ReactNode;
  tertiaryMain?: ReactNode;
  onChangeInputSearch: (a: string) => void;
  clickProfileAvatar: (a: any) => void;
  profileAvatar: string;
  valueInputSearch: string;
  originCoordinates?: ICoordinates;
  destinyCoordinates?: ICoordinates;
  showInput?: boolean;
  airportsPins: AirportPin[];
  isALinkHeader?: boolean;
  clickOnLogo?: () => void;
  inputSearchClick?: ((a: any) => void) | undefined;
  onClickLogin?: (a: any) => void;
}

const Desk: React.FC<DeskProps> = ({
  hasANewMessage,
  originCoordinates,
  destinyCoordinates,
  principalMain,
  secondaryMain,
  tertiaryMain,
  onChangeInputSearch,
  clickProfileAvatar,
  profileAvatar,
  valueInputSearch,
  showInput,
  airportsPins,
  majorMain,
  isALinkHeader,
  clickOnLogo,
  inputSearchClick,
  onClickLogin,
}) => {
  const [isPrincipalMainVisible, setIsPrincipalMainVisible] = useState(false);
  const [isSecondaryMainVisible, setIsSecondaryMainVisible] = useState(false);
  const [isTertiaryMainVisible, setIsTertiaryMainVisible] = useState(false);
  const [inputSearchResultOptions, setInputSearchResultOptions] = useState(
    {} as IOptionInputTextSelectProps[]
  );

  const { userInfo } = useUserInfo();
  const { openUseModal, setModalContent, setOpenUseModal } = useModal();
  const { setOriginDestiny } = useSearchOriginDestiny();
  const [showInputState, setShowInputState] = useState(true);
  const [isSlideUpVisible, setIsSlidUpVisible] = useState(false);
  const [airports, setAirports] = useState([]);
  const [pageWidthSize, setPageWidthSize] = useState(window.innerWidth);
  const {
    setMainScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setPageWidthSize(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    setInputSearchResultOptions({} as IOptionInputTextSelectProps[]);
  }, [isSlideUpVisible]);

  useEffect(() => {
    if (principalMain) {
      setIsPrincipalMainVisible(true);
    } else {
      setIsPrincipalMainVisible(false);
    }
    changeInputSearchVisible(principalMain);
  }, [principalMain]);

  useEffect(() => {
    if (secondaryMain) {
      setIsSecondaryMainVisible(true);
    } else {
      setIsSecondaryMainVisible(false);
    }
    changeInputSearchVisible(secondaryMain);
  }, [secondaryMain]);

  useEffect(() => {
    if (tertiaryMain) {
      setIsTertiaryMainVisible(true);
    } else {
      setIsTertiaryMainVisible(false);
    }
    changeInputSearchVisible(tertiaryMain);
  }, [tertiaryMain]);

  const changeInputSearchVisible = (type: ReactNode) => {
    if (React.isValidElement(type) && typeof type.type !== "string") {
      changeStateSearchVisible(type.type.name);
    } else {
      return setShowInputState(true);
    }
  };

  const changeStateSearchVisible = async (type: string) => {
    if (isALinkHeader) return;

    if (type.includes("UserSettings")) {
      return setShowInputState(false);
    }

    try {
      const request = await getAirports(userInfo["@air-fly_access-token"]);
      if (request.res.data) {
        setAirports(request.res.data);
      }
      return setShowInputState(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (value: string) => {
    setOriginDestiny({
      destiny: { iata: value, lat: 0, lon: 0 },
      origin: { iata: originCoordinates?.iataCode || "", lat: 0, lon: 0 },
    });
  };

  const handleInputChange = async (search: string, category?: string) => {
    if (
      userInfo["@air-fly_user-plan"] === "global" ||
      userInfo["@air-fly_user-plan"] === "travel"
    ) {
      try {
        const request = await getAirportsCodes(
          userInfo["@air-fly_access-token"],
          {
            search,
          }
        );

        setInputSearchResultOptions(
          request.res.data.map((airport: IAirportsSearch) => {
            return {
              label: `${airport.iata} - ${airport.name}`,
              detailsText: `${airport.city}, ${airport.country}`,
              value: JSON.stringify(airport),
            } as IOptionInputTextSelectProps;
          })
        );
      } catch (error) {
        console.error(error);
      }
    } else {
      setModalContent(
        <section className="payment-response">
          <span className="icon icon-airplane icon-md icon-orange "></span>
          <h5 className="no-margin margin-top-16">
            <b>Oops, you need to upgrade your plan!</b>
          </h5>
          <p className="small-p margin-bottom-16">
            Unlock all filters with our premium plans!
          </p>
          <div className="buttons-container">
            <Button
              children={"Upgrade Now"}
              buttonStyle={"primary"}
              onClick={() => {
                handleTryAgain();
              }}
            />
            <Button
              children={"Continue Free"}
              buttonStyle={"tertiary"}
              onClick={() => {
                setOpenUseModal(false);
              }}
            />
          </div>
        </section>
      );
      setOpenUseModal(true);
    }
  };

  const searchFilteredConsultCallback = (data: FilterObjectSlidUp) => {
    // y: "economic",
    // w: "premium",
    // j: "executive",
    // f: "first",
    const classSymbol: "Y" | "W" | "J" | "F" | undefined =
      data.classSelected === "economic"
        ? "Y"
        : data.classSelected === "premium"
        ? "W"
        : data.classSelected === "executive"
        ? "J"
        : data.classSelected === "first"
        ? "F"
        : undefined;

    const prepareParams = {
      origin: {
        iata: data.departureIATA,
        lat: data.departureCoord.lat,
        lon: data.departureCoord.lon,
      },
      destiny: {
        iata: data.destinationIATA,
        lat: data.destinationCoord.lat,
        lon: data.destinationCoord.lon,
        classes: classSymbol ? [classSymbol] : undefined,
        direct: data.stopsSelected === "Direct",
        date: data.dateSelected || undefined,
      },
    };
    setOriginDestiny(prepareParams);
  };

  const handleTryAgain = () => {
    setOpenUseModal(false);
    setMainScreen("");
    setPrincipalScreen("/user-settings-subscriptions");
    setSecondaryScreen("");
    setTertiaryScreen("");
  };

  const clickFilterIcon = () => {
    if (userInfo["@air-fly_user-plan"] === "global") {
      if (pageWidthSize <= 768) {
        setIsSlidUpVisible(!isSlideUpVisible);
      } else {
        setModalContent(
          <ModalFilterFlights
            inputSearchResultOptions={inputSearchResultOptions}
            titleHead="Add Filter"
            titleText="Destination"
            primaryButtonText="Add Filter"
            filterResultsCallback={searchFilteredConsultCallback}
          />
        );
        setTimeout(() => {
          setOpenUseModal(true);
        }, 0);
      }
    } else {
      setModalContent(
        <section className="payment-response">
          <span className="icon icon-airplane icon-md icon-orange "></span>
          <h5 className="no-margin margin-top-16">
            <b>Oops, you need to upgrade your plan!</b>
          </h5>
          <p className="small-p margin-bottom-16">
            Unlock all filters with our premium plans!
          </p>
          {/* <p className="small-p margin-bottom-16">
            Unlock all filters with the AirFly Ultimate Plan! Use the promo
            code: "AMIGOS" for 30 days trial.
          </p> */}
          <div className="buttons-container">
            <Button
              children={"Upgrade Now"}
              buttonStyle={"primary"}
              onClick={() => {
                handleTryAgain();
              }}
            />
            <Button
              children={"Continue Free"}
              buttonStyle={"tertiary"}
              onClick={() => {
                setOpenUseModal(false);
              }}
            />
          </div>
        </section>
      );
      setOpenUseModal(true);
    }
  };

  return (
    <div id="desk-wrapper">
      <section className="desk-wrapper__header-wrapper">
        <HeaderNavigate
          isALinkHeader={isALinkHeader}
          userAvatarImage={profileAvatar}
          className="padding-hor-24"
          clickAvatarCallback={clickProfileAvatar}
          handleSelectChange={handleSelectChange}
          inputIconRight={FilterIconGray}
          inputPlaceholder="Where to?"
          inputSearchResultOptions={inputSearchResultOptions}
          onChangeInput={handleInputChange}
          showInput={showInputState}
          onClickIconRight={clickFilterIcon}
          showLogo={true}
          inputSearchClick={inputSearchClick}
          hasIconRightButton={true}
          searchInput
          clickOnLogo={clickOnLogo}
          onClickLogin={onClickLogin}
        />
      </section>
      <main
        className={`main-page-desk ${
          isPrincipalMainVisible || isSecondaryMainVisible
            ? `space-without-header ${
                isTertiaryMainVisible ? "grid-template-changed" : ""
              }`
            : ""
        }`}
      >
        <div
          className={`map-main ${
            isSecondaryMainVisible &&
            isTertiaryMainVisible &&
            isPrincipalMainVisible
              ? "hide-map-for-tertiary-main"
              : isPrincipalMainVisible && !isSecondaryMainVisible
              ? "hide-map"
              : isSecondaryMainVisible && isPrincipalMainVisible
              ? "secondary-hide-map"
              : isSecondaryMainVisible && !isPrincipalMainVisible
              ? "only-secondary-main-is-show"
              : "show-map"
          }`}
        >
          {majorMain ? (
            majorMain
          ) : (
            <MapRoute
              originCoordinates={originCoordinates}
              destinyCoordinates={destinyCoordinates}
              airportsPinsList={airportsPins}
            />
          )}
        </div>

        <div
          className={`principal-main ${
            isPrincipalMainVisible && !isSecondaryMainVisible
              ? "show-principal-main"
              : isPrincipalMainVisible &&
                isSecondaryMainVisible &&
                !isTertiaryMainVisible
              ? "show-principal-main show-principal-and-secondary-main"
              : isPrincipalMainVisible &&
                isSecondaryMainVisible &&
                isTertiaryMainVisible
              ? "show-principal-main hide-principal-main-mobile"
              : "hide-principal-main"
          }`}
        >
          {principalMain}
          <SlidUpFilterFlights
            closeSlidUpAreaCallback={setIsSlidUpVisible}
            isOpen={isSlideUpVisible}
            onChangeInput={handleInputChange}
            filterResultsCallback={searchFilteredConsultCallback}
            titleHead="Add Filter"
            titleText="Destination"
            primaryButtonText="Add Filter"
          />
        </div>
        <div
          className={`secondary-main ${
            isSecondaryMainVisible && !isTertiaryMainVisible
              ? "show-secondary-main"
              : isSecondaryMainVisible &&
                isTertiaryMainVisible &&
                isPrincipalMainVisible
              ? "show-secondary-main show-secondary-and-tertiary-main"
              : isSecondaryMainVisible &&
                isTertiaryMainVisible &&
                !isPrincipalMainVisible
              ? "show-secondary-main only-secondary-and-tertiary-main-show"
              : "hide-secondary-main"
          }`}
        >
          {secondaryMain}
        </div>

        <div
          className={`tertiary-main ${
            isTertiaryMainVisible &&
            isSecondaryMainVisible &&
            isPrincipalMainVisible
              ? "show-tertiary-main"
              : !isPrincipalMainVisible &&
                isSecondaryMainVisible &&
                isTertiaryMainVisible
              ? "show-all-main"
              : "hide-tertiary-main"
          }`}
        >
          {tertiaryMain}
        </div>
      </main>
    </div>
  );
};

export default Desk;
