import fetchApi from "../fetchApi";

const getCurrentPlan = async (token: string) => {
  return await fetchApi({
    service: "plans",
    endPoint: "/user/current",
    method: "GET",
    token,
  });
};

export default getCurrentPlan;
