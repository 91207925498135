import React from "react";
import "./styles.scss";
import Airplane from "../../../assets/icons/airPlanes/airplane-orange.svg";
import FlyRoute from "../../../assets/icons/fliesIcons/fly-route.svg";
import Barcode from "../../../assets/images/barcode.png";
import FlyShield from "../../../assets/images/fly-shield.png";
import Button from "components/Buttons/Button";
import LinearTimer from "components/Timer/TimeBar";

interface AirlinesQuizCardProps {
  answers: IAnswer[];
  airportNumber: number;
  airportPoints: string;
  pointsPoints: number;
  pointsCorrect: boolean;
  question: string;
  congratulations?: boolean;
  timer?: number;
  stopTimer?: boolean;
  resetTimer?: boolean;
  className?: string;
  clickAnswer: (answer: string) => void;
  clickFlyAgain: (a: any) => void;
  clickStopPlaying: (a: any) => void;
  onTimeEnd?: () => void;
}

export type IAnswer = {
  iata: string;
  answerStatus: "non-selected" | "selected" | "wrong" | "correct";
};

const AirlinesQuizCard: React.FC<AirlinesQuizCardProps> = (props) => {
  const {
    answers,
    airportNumber,
    airportPoints,
    pointsCorrect,
    pointsPoints,
    question,
    congratulations,
    timer,
    stopTimer,
    resetTimer,
    className,
    clickAnswer,
    clickFlyAgain,
    clickStopPlaying,
    onTimeEnd,
  } = props;

  return (
    <div className={`airline-quiz-tickets-cards ${className ?? ""}`}>
      <div className={`airline-quiz-tickets`}>
        <img src={FlyRoute} alt="fly route" />
        <section className="airline-quiz-tickets__header margin-bottom-24">
          <img src={Airplane} alt="airplane" />
          <p className="header__title">
            Airport<b>Qwz</b>
          </p>
        </section>
        <section className="airline-quiz-tickets__right-container">
          <p className="no-margin header-section-text-sup">AIRPORTS</p>
          <p className="no-margin header-section-text-middle">
            {airportNumber}
          </p>
          <p className="no-margin header-section-text-sub">{airportPoints}</p>
        </section>
        <section className="airline-quiz-tickets__details">
          {timer && (
            <LinearTimer
              duration={timer}
              stopTimer={stopTimer}
              resetTimer={resetTimer}
              onTimeEnd={onTimeEnd}
            />
          )}
          <p className="details__text">Test Your Knowledge</p>
        </section>
        <section className="airline-quiz-tickets__left-container">
          <p className="no-margin header-section-text-sup">POINTS</p>
          <p className="no-margin header-section-text-middle">{pointsPoints}</p>
          <p className="no-margin header-section-text-sub">
            {pointsCorrect ? "Correct" : "Incorrect"}
          </p>
        </section>
        <hr className={`dotted-hr`} />
        {congratulations ? (
          <section className="congratulations-quiz">
            <div className="congratulations-quiz__text-container">
              <h2 className="no-margin text-container__title">
                Congratulations!
              </h2>
              <p className="no-margin text-container__explanation-text">
                You’re ready to explore the world.
              </p>
            </div>
            <img
              src={FlyShield}
              alt="fly insignia"
              className="margin-vert-24"
            />

            <div className="congratulations-quiz__buttons-container">
              <Button buttonStyle="primary" onClick={clickFlyAgain}>
                Continue to Fly
              </Button>
              <Button buttonStyle="secondary" onClick={clickStopPlaying}>
                Stop Playing
              </Button>
            </div>
          </section>
        ) : (
          <section className="question-ticket-container">
            <h2 className="question-ticket-container__question margin-bottom-24">
              {question}
            </h2>
            <div className="question-ticket-container__answers">
              {answers.map((answer, index) => (
                <button
                  className={`answer-buttons ${answer.answerStatus}`}
                  key={index}
                  onClick={() => clickAnswer(answer.iata)}
                >
                  {answer.iata}
                </button>
              ))}
            </div>
          </section>
        )}

        <section className="barcode-container margin-top-24">
          <img
            className="barcode-container__barcode"
            src={Barcode}
            alt="barcode"
          />
          <p className="barcode-container__text no-margin">
            <b>AirFly</b> AirportQWZ
          </p>
        </section>
      </div>
    </div>
  );
};

export default AirlinesQuizCard;
