import fetchApi from "api/fetchApi";

const postFavoriteFlight = async (token: string, flightId?: string) => {
  return await fetchApi({
    service: "users",
    endPoint: `/favorite/${flightId}`,
    method: "POST",
    token,
  });
};

export default postFavoriteFlight;
