import fetchApi from "../fetchApi";

export type PatchUserProfileBody = {
  displayName: string;
  newsLetter: boolean;
};

const patchUserProfile = async (
  token: string,
  data: Partial<PatchUserProfileBody>
) => {
  return await fetchApi({
    service: "users",
    endPoint: `/profile`,
    method: "PATCH",
    token,
    data,
  });
};

export default patchUserProfile;
