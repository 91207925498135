import fetchApi from "../fetchApi";

const postUpdatePassword = async (data: {
  token: string;
  newPassword: string;
}) => {
  return await fetchApi({
    service: "auth",
    endPoint: "/update-password",
    method: "POST",
    data,
  });
};

export default postUpdatePassword;
