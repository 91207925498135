const FlightClasses = {
  economy: "economic",
  economic: "economic",
  premium: "premium",
  executive: "executive",
  first: "first",
  business: "executive",
  y: "economic",
  w: "premium",
  j: "executive",
  f: "first",
} as const;

type FlightClassKey = keyof typeof FlightClasses;

export const flightClassName = (key: string): string | undefined => {
  if (key in FlightClasses) {
    return FlightClasses[key as FlightClassKey];
  }

  return undefined;
};
