export const handleGetLocation = (): Promise<{
  latitude: number;
  longitude: number;
}> => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (err) => {
          if (err.code === err.PERMISSION_DENIED) {
            reject("Permission Denied");
          } else {
            reject(new Error(err.message));
          }
        }
      );
    } else {
      reject(new Error("Geolocation is not supported by your browser"));
    }
  });
};
