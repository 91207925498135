import fetchApi from "../fetchApi";

const postSendLinkEmailToVerify = async (token: string) => {
  return await fetchApi({
    service: "users",
    endPoint: `/request-email-verification`,
    method: "POST",
    token,
  });
};

export default postSendLinkEmailToVerify;
