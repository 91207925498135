import fetchApi from "../fetchApi";

const postPhoneNumberVerify = async (token: string, phone: string) => {
  return await fetchApi({
    service: "sms",
    endPoint: `/send`,
    method: "POST",
    token,
    data: {
      phone,
    },
  });
};

export default postPhoneNumberVerify;
