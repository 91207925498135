import fetchApi from "../fetchApi";

const getUserProfile = async (token: string) => {
  return await fetchApi({
    service: "users",
    endPoint: `/profile`,
    method: "GET",
    token,
  });
};

export default getUserProfile;
