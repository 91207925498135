import fetchApi from "../fetchApi";

const userAuthLogin = async (data: { email: string; password: string }) => {
  return await fetchApi({
    service: "auth",
    endPoint: "/login",
    method: "POST",
    data,
  });
};

export default userAuthLogin;
