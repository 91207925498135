import getAirportsCodes from "api/flights/getAirportsCodes";

export const getAirportsByIATA = async (
  token: string,
  IATA: string,
  queryToken?: string
) => {
  const storedCodes = JSON.parse(
    localStorage.getItem("@air-fly-iata-codes") || "{}"
  );

  if (!storedCodes[IATA]) {
    const request = await getAirportsCodes(token, { iata: IATA }, queryToken);

    const location = request.res.data[0]?.city || "Unknown Location";

    storedCodes[IATA] = location;
    localStorage.setItem("@air-fly-iata-codes", JSON.stringify(storedCodes));

    return location;
  } else {
    return storedCodes[IATA];
  }
};
