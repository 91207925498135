import fetchApi from "../fetchApi";

const getPlans = async (token: string) => {
  return await fetchApi({
    service: "plans",
    method: "GET",
    token,
  });
};

export default getPlans;
