import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import Header from "components/Headers/Header";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";
import Toast from "components/Toast";
import { useUserInfo } from "contexts/userInfo";
import { IToast } from "types/toast";
import userAuthLogin from "api/auth/postLogin";
import { clearLocalStorage } from "utils/clearLocalStorage";
import getUserProfile from "api/user/getUserProfile";
import { handleGetLocation } from "utils/getUserGeoLocation";
import patchUserCoordinates from "api/user/patchUserCoordinates";
import googleIcon from "../../../assets/icons/socialMedia/google-logo-gray.svg";
import openedEye from "../../../assets/icons/eyes/opened-eye-gray.svg";

import getAuthGoogle from "api/auth/getAuthGoogle";
import Separator from "components/Separator";
import PasswordStrengthBar from "react-password-strength-bar";
import createUser from "api/auth/postCreateUser";
import { useModal } from "contexts/useModal";
import Loading from "components/Loading";

const LoginAndCreateAccountModalContent = ({
  navigateToHome,
}: {
  navigateToHome: () => void;
}) => {
  const [emailInputWarning, setEmailInputWarning] = useState(false);
  const { setModalContent, setOpenUseModal } = useModal();

  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordInputWarning, setPasswordInputWarning] = useState(false);
  const { userInfo, setUserInfo } = useUserInfo();
  const [showLoginPage, setShowLoginPage] = useState(true);
  //////////////////////////
  const [displayName, setDisplayName] = useState("");
  const [newsLetter, setNewsLetter] = useState(true);
  const [createdPassword, setCreatedPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [displayNameWarning, setDisplayNameWarning] = useState(false);
  const [token, setToken] = useState("");
  const loginCreateAccountModalRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      loginCreateAccountModalRef.current &&
      !loginCreateAccountModalRef.current.contains(event.target as Node)
    ) {
      setOpenUseModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const loginSubmit = async (method: "interAuth" | "oauthGoogle") => {
    if (isLoading) return;

    if (method === "oauthGoogle") {
      const responseAuth = getAuthGoogle();
    } else if (method === "interAuth") {
      if (userEmail.length < 5) {
        setToastConfig({
          description: "You need to add your email to continue",
          title: "Email is empty",
          type: "info",
        });
        setToastShow(true);
        setEmailInputWarning(true);

        setTimeout(() => {
          setEmailInputWarning(false);
          setToastShow(false);
        }, 4000);

        return;
      }
      if (!userEmail.includes("@") || !userEmail.includes(".com")) {
        setToastConfig({
          description: "Add a valid email to continue.",
          title: "Invalid Email",
          type: "info",
        });
        setToastShow(true);
        setEmailInputWarning(true);

        setTimeout(() => {
          setEmailInputWarning(false);
          setToastShow(false);
        }, 4000);

        return;
      }

      if (!userPassword) {
        setToastConfig({
          description: "You need to add your password to continue",
          title: "Password is empty",
          type: "info",
        });

        setToastShow(true);
        setPasswordInputWarning(true);
        setTimeout(() => {
          setPasswordInputWarning(false);
          setToastShow(false);
        }, 4000);

        return;
      }

      setIsLoading(true);

      const prepareData = {
        email: userEmail.trim(),
        password: userPassword,
      };
      try {
        const responseAuth = await userAuthLogin(prepareData);

        switch (responseAuth.status) {
          case 200:
            const access_token = responseAuth.res.access_token;
            setToken(responseAuth.res.access_token);
            tokenTreatment(responseAuth?.status, access_token);
            break;

          case 401:
            setIsLoading(false);
            setToastConfig({
              description: "Check your credentials and, please, try again",
              title: "Error",
              type: "error",
            });
            setToastShow(true);
            setEmailInputWarning(true);
            setPasswordInputWarning(true);

            setTimeout(() => {
              setEmailInputWarning(false);
              setPasswordInputWarning(false);
              setToastShow(false);
            }, 4000);
            break;
          default:
            setToastConfig({
              description:
                "We had a problem making the request. Please try again",
              title: "Sorry",
              type: "error",
            });

            setToastShow(true);
            setTimeout(() => {
              setToastShow(false);
            }, 4000);
            break;
        }
      } catch (error) {
        console.error(error);
        setToastConfig({
          description: "We had a problem making the request. Please try again",
          title: "Sorry",
          type: "error",
        });

        setToastShow(true);
        setTimeout(() => {
          setToastShow(false);
        }, 4000);
      }
    }
  };

  const tokenTreatment = async (status: number | null, token: string) => {
    switch (status) {
      case 200:
        try {
          clearLocalStorage();
          const userProfile = await getUserProfile(token);
          setUserInfo("@air-fly_access-token", token);

          switch (userProfile.status) {
            case 200:
              if (
                !userInfo["@air-fly_geo_location_permission"] ||
                !JSON.parse(userInfo["@air-fly_geo_location_permission"])
              ) {
                handleGetLocation()
                  .then(async (res) => {
                    setUserInfo(
                      "@air-fly_user_lat",
                      JSON.stringify(res.latitude)
                    );
                    setUserInfo(
                      "@air-fly_user_lon",
                      JSON.stringify(res.longitude)
                    );

                    await patchUserCoordinates(
                      token,
                      res.longitude,
                      res.latitude
                    );
                  })
                  .catch((res) =>
                    setUserInfo("@air-fly_geo_location_permission", "false")
                  )
                  .finally(() => {
                    setUserInfo("@air-fly_access-token", token);
                    setUserInfo(
                      "@air-fly_display_name",
                      userProfile.res.displayName
                    );
                    setUserInfo(
                      "@air-fly_user_avatar",
                      userProfile.res.avatarUrl
                    );
                    setUserInfo("@air-fly_user_email", userProfile.res.email);
                    setUserInfo("@air-fly_user_id", userProfile.res.id);
                    setUserInfo(
                      "@air-fly_newsletter",
                      userProfile.res.newsLetter
                    );
                    setUserInfo("@air-fly_user_role", userProfile.res.role);
                    setUserInfo(
                      "@air-fly_user_country",
                      userProfile.res.country
                    );
                    setUserInfo("@air-fly_user_city", userProfile.res.city);
                    setUserInfo("@air-fly_user_phone", userProfile.res.phone);
                    setUserInfo(
                      "@air-fly_phone-verified",
                      userProfile.res.isPhoneVerified
                    );

                    setOpenUseModal(false);
                    navigateToHome();
                  });
              }
              break;
            case 403:
              setIsLoading(false);

              break;
            default:
              setIsLoading(false);
              setToastConfig({
                description:
                  "We had a problem making the request. Please try again",
                title: "Sorry",
                type: "error",
              });

              setToastShow(true);
              setTimeout(() => {
                setToastShow(false);
              }, 4000);
              break;
          }
        } catch (error) {
          console.error(error);
          setIsLoading(false);
          setToastConfig({
            description:
              "We had a problem making the request. Please try again",
            title: "Sorry",
            type: "error",
          });

          setToastShow(true);
          setTimeout(() => {
            setToastShow(false);
          }, 4000);
        }

        break;
      case 401:
        setIsLoading(false);
        setToastConfig({
          description: "Check your credentials and, please, try again",
          title: "Error",
          type: "error",
        });
        setToastShow(true);
        setEmailInputWarning(true);
        setPasswordInputWarning(true);

        setTimeout(() => {
          setEmailInputWarning(false);
          setPasswordInputWarning(false);
          setToastShow(false);
        }, 4000);

        break;

      default:
        setToastConfig({
          description: "We had a problem making the request. Please try again",
          title: "Sorry",
          type: "error",
        });

        setToastShow(true);
        setTimeout(() => {
          setToastShow(false);
        }, 4000);
        break;
    }
  };

  const createAccountSubmit = async () => {
    if (displayName.trim().length < 3) {
      setToastConfig({
        description: "Your display name should be at least 3 characters long",
        title: "Short Display name",
        type: "warning",
      });

      setToastShow(true);
      setDisplayNameWarning(true);

      setTimeout(() => {
        setToastShow(false);
        setDisplayNameWarning(false);
      }, 4000);
      return;
    }

    if (userEmail.length < 5) {
      setToastShow(true);
      setEmailInputWarning(true);

      setToastConfig({
        description: "You need to add your email to continue",
        title: "Email is empty",
        type: "info",
      });

      setTimeout(() => {
        setEmailInputWarning(false);
        setToastShow(false);
      }, 4000);

      return;
    }

    if (!userEmail.includes("@") || !userEmail.includes(".com")) {
      setToastShow(true);
      setEmailInputWarning(true);

      setToastConfig({
        description: "Add a valid email to continue.",
        title: "Invalid Email",
        type: "info",
      });

      setTimeout(() => {
        setEmailInputWarning(false);
        setToastShow(false);
      }, 4000);

      return;
    }

    if (confirmPassword.trim().length < 8) {
      setToastConfig({
        description: "Your password should be at least 8 characters long",
        title: "Short Pass",
        type: "warning",
      });

      setToastShow(true);
      setPasswordInputWarning(true);

      setTimeout(() => {
        setToastShow(false);
        setPasswordInputWarning(false);
      }, 4000);
      return;
    }

    if (createdPassword.trim() !== confirmPassword.trim()) {
      setToastConfig({
        description: "Check passwords and try again",
        title: "Passwords don't match",
        type: "warning",
      });

      setToastShow(true);
      setPasswordInputWarning(true);

      setTimeout(() => {
        setToastShow(false);
        setPasswordInputWarning(false);
      }, 4000);
      return;
    }

    setIsLoading(true);

    const prepareData = {
      displayName: displayName,
      email: userEmail,
      password: createdPassword,
    };
    const request = await createUser(prepareData);

    switch (request.status) {
      case 201:
        handleGetLocation()
          .then(async (res) => {
            setUserInfo("@air-fly_user_lat", JSON.stringify(res.latitude));
            setUserInfo("@air-fly_user_lon", JSON.stringify(res.longitude));

            await patchUserCoordinates(
              request.res.access_token,
              res.longitude,
              res.latitude
            );
          })
          .catch((res) =>
            setUserInfo("@air-fly_geo_location_permission", "false")
          )
          .finally(() => {
            setUserInfo("@air-fly_access-token", request.res.access_token);
            setUserInfo("@air-fly_display_name", request.res.displayName);
            setUserInfo("@air-fly_user_avatar", request.res?.avatarUrl);
            setUserInfo("@air-fly_user_email", request.res.email);
            setUserInfo("@air-fly_user_id", request.res.id);
            setUserInfo("@air-fly_newsletter", JSON.stringify(newsLetter));
            setUserInfo("@air-fly_user_role", request.res.role);
            setUserInfo("@air-fly_user_country", request.res?.country);
            setUserInfo("@air-fly_user_city", request.res?.city);

            // setComponent(sedEmailVerify);
          });

        break;
      case 409:
        setToastConfig({
          description: "Already registered user",
          title: "Oops...!",
          type: "info",
        });
        setToastShow(true);

        setTimeout(() => setToastShow(false), 4000);

        break;
      default:
        setToastConfig({
          description:
            "Something went wrong on user creation. Please try again.",
          title: "Error",
          type: "error",
        });
        setToastShow(true);

        setTimeout(() => setToastShow(false), 4000);
        break;
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      loginSubmit("interAuth");
    }
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <div
      className="login-and-create-account-modal-content"
      ref={loginCreateAccountModalRef}
    >
      {showLoginPage ? (
        <div>
          <Header
            headerIcon="airFly"
            title="Welcome!"
            titleClass="header"
            subTitle={`For your security, please log in to continue accessing our
					platform.`}
          />
          <div className="login-container margin-top-24">
            <InputText
              value={userEmail.toLowerCase()}
              inputType="email"
              placeholder="Email"
              // textTransform="none"
              onChangeInput={(e) => setUserEmail(e)}
              isWarning={emailInputWarning}
              className="no-margin"
            />
            <InputText
              inputType="password"
              value={userPassword}
              placeholder="Password"
              onChangeInput={(e) => setUserPassword(e)}
              onKeyDown={onKeyDown}
              isWarning={passwordInputWarning}
              className="no-margin"
            />
            <Button
              disabled={isLoading}
              buttonStyle="tertiary"
              onClick={() => loginSubmit("interAuth")}
            >
              Login
            </Button>

            <Button
              buttonStyle="quaternary"
              onClick={() => loginSubmit("oauthGoogle")}
            >
              <img src={googleIcon} alt="google icon" />
            </Button>
          </div>
          <div className="buttons-container margin-top-24">
            <Button
              disabled={isLoading}
              buttonStyle="quintenary"
              onClick={navigateToHome}
            >
              Forgot Password
            </Button>
            <Button
              disabled={isLoading}
              buttonStyle="primary"
              onClick={() => setShowLoginPage(false)}
            >
              Create account
            </Button>
            <SubText>
              By tapping any of the actions above, you acknowledge that you are
              over the age of 18 and agree to our Terms of Services. Learn how
              we process your data in our Privacy Policy.
            </SubText>
          </div>
        </div>
      ) : (
        <div>
          <Header
            headerIcon="airFly"
            backButtonClick={() => setShowLoginPage(true)}
            title={
              <>
                Create your <br />
                account
              </>
            }
            titleClass="header"
          />
          <InputText
            inputType="text"
            placeholder="Display Name"
            value={displayName}
            onChangeInput={setDisplayName}
            isWarning={displayNameWarning}
          />
          <SubText icon={openedEye}>This will be shown on your profile</SubText>
          <Separator className="margin-top-8 margin-bottom-24" />
          <InputText
            inputType="email"
            placeholder="Email"
            onChangeInput={setUserEmail}
            value={userEmail}
            isWarning={emailInputWarning}
          />
          <SubText
            checkAnswer
            checkValueCallback={setNewsLetter}
            className="margin-vert-8"
          >
            I want to receive news, updates, and offers from AirFly.
          </SubText>
          <div className="password-inputs-container">
            <InputText
              value={createdPassword}
              placeholder="Password"
              inputType="password"
              onChangeInput={(e) => setCreatedPassword(e)}
              isWarning={passwordInputWarning}
              className="no-margin"
            />
            <InputText
              value={confirmPassword}
              placeholder="Confirm Password"
              inputType="password"
              onChangeInput={(e) => setConfirmPassword(e)}
              isWarning={passwordInputWarning}
              className="no-margin"
            />
            <PasswordStrengthBar
              minLength={8}
              barColors={[
                "#dddddd",
                "var(--peach)",
                "#FFC045",
                "var(--green)",
                "var(--green",
              ]}
              scoreWords={[
                "(add more characters to strengthen) very weak",
                "(add more characters to strengthen) weak",
                "good",
                "strong",
                "very strong",
              ]}
              scoreWordClassName="text-password-bar"
              shortScoreWord="Your password should be at least 8 characters long"
              password={createdPassword}
            />
          </div>
          <div className="create-account-page__buttons-container padding-vert-16">
            <Button
              disabled={isLoading}
              buttonStyle="quintenary"
              onClick={() => setShowLoginPage(true)}
            >
              Already have an account?<span className="highlight">Login</span>
            </Button>
            <Button
              disabled={isLoading}
              buttonStyle="primary"
              onClick={createAccountSubmit}
            >
              Create Account
            </Button>
          </div>
        </div>
      )}

      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default LoginAndCreateAccountModalContent;
