import React, { ReactNode } from "react";
import "./styles.scss";
import Button from "../../Buttons/Button";

interface TextActionCardProps {
  className?: string;
  principalText: string;
  secondaryText: string | ReactNode;
  headerIcon?: string;
  buttons?: Buttons[];
  transparentBackground?: boolean;
}

type Buttons = {
  text: string;
  buttonAction: (a: any) => void;

  buttonStyle:
    | "primary"
    | "secondary"
    | "tertiary"
    | "quaternary"
    | "quintenary";
};

const TextActionCard: React.FC<TextActionCardProps> = (props) => {
  const {
    className,
    principalText,
    secondaryText,
    headerIcon,
    buttons,
    transparentBackground,
  } = props;

  const classNameCard = [
    className,
    transparentBackground ? "transparent-background" : "",
  ]
    .join(" ")
    .trim();

  return (
    <div id={`text-action-card`} className={classNameCard}>
      {headerIcon && (
        <div className="header-image-wrapper margin-bottom-16">
          <img src={headerIcon} alt="header icon" />
        </div>
      )}

      <h2 className="no-margin principal-text">{principalText}</h2>
      <p className="explanation-text">{secondaryText}</p>
      {buttons?.map((btn, index) => (
        <div
          key={index}
          className="text-action-card__buttons-container margin-top-24"
        >
          <Button buttonStyle={btn.buttonStyle} onClick={btn.buttonAction}>
            {btn.text}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default TextActionCard;
